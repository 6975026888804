import React, {useState} from "react";
import {includes, reduce} from "lodash";
import {Col, Empty, List, Row, Switch, Typography} from "antd";

import {IApplicationListApplication} from "../../application/actions/fetch_applications";
import {cardApplicationListGridSizes} from "../../offer/style.utils";
import {UserOwnApplication} from "./UserOwnApplication";
import {ApplicationStatus} from "../../firebase/fields/ApplicationStatus";


interface IProps {
    userId: string;
    applications: IApplicationListApplication[];
}

interface isStatusActive {
    pending?: boolean, rejected?: boolean, accepted?: boolean
}

const {Title} = Typography;

export const UserOwnApplicationsSection = (props: IProps) => {
    const [activeFilters, setFilters] = useState<ApplicationStatus[]>([ApplicationStatus.ACCEPTED, ApplicationStatus.REJECTED, ApplicationStatus.PENDING]);

    if (props.applications.length === 0) {
        return null;
    }


    const renderHeader = () => {
        return (
            <Row>
                <Col offset={1} span={19}>
                    <Title level={2} style={{
                        fontWeight: 600
                    }}>
                        Moje zgłoszenia
                    </Title>
                </Col>
            </Row>
        )
    };

    const availableStatusesMap = reduce(props.applications, (acc: isStatusActive, val: any) => {
        if (!acc.pending && val.status === ApplicationStatus.PENDING) {
            acc = {...acc, pending: true}
        }
        if (!acc.rejected && val.status === ApplicationStatus.REJECTED) {
            acc = {...acc, rejected: true}
        }
        if (!acc.accepted && val.status === ApplicationStatus.ACCEPTED) {
            acc = {...acc, accepted: true}
        }
        return acc
    }, {});


    const filteredApplications = props.applications.filter(application => activeFilters.includes(application.status));
    const handleFilterClick = (status: ApplicationStatus) => {
        setFilters((prevFilters) => includes(prevFilters, status) ? prevFilters.filter(filter => filter !== status) : [...prevFilters, status]);
    };
    const renderFilters = () => {
        return (
            <div style={{marginLeft: "3%"}}>
                {availableStatusesMap.accepted && <Switch style={{margin: "0 1%" }} defaultChecked checkedChildren={"Zaakceptowane"} unCheckedChildren={"Zaakceptowane"}
                            onClick={() => handleFilterClick(ApplicationStatus.ACCEPTED)}/>}

                {availableStatusesMap.pending && <Switch style={{margin: "0 1%"}}  defaultChecked checkedChildren={"Oczekujące"} unCheckedChildren={"Oczekujące"}
                            onClick={() => handleFilterClick(ApplicationStatus.PENDING)}/>}

                {availableStatusesMap.rejected && <Switch style={{margin: "0 1%"}}  defaultChecked checkedChildren={"Odrzucone"} unCheckedChildren={"Odrzucone"}
                            onClick={() => handleFilterClick(ApplicationStatus.REJECTED)}/>}
            </div>
        )
    };
    return (
            <div id={"moje-zgloszenia"} style={{marginTop: "1%"}}>
                {renderHeader()}
                {renderFilters()}
                <List
                    grid={cardApplicationListGridSizes as any}
                    style={{padding: "1% 4%"}}
                    size="large"
                    locale={{emptyText: <Empty style={{fontSize: 20}} description={`Wygląda na to, że wyłączyłeś wszystkie filtry ;)`}/>}}
                    dataSource={filteredApplications}
                    renderItem={(application) => (
                        <UserOwnApplication application={application}/>
                    )}
                />
            </div>
    );
};

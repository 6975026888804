import React, {useState} from "react";
import {TimePicker} from "antd";
import {FieldProps} from "formik";
import moment, {Moment} from "moment-timezone";
import {getIsMobile} from "../../app/utils/check_device";


interface IProps extends FieldProps<any> {
    placeholder: string;
    onChange: (value: Moment) => void;
    defaultOpenValue: Moment;
    style?: any;
}

export const TimePickerField = (props: IProps) => {
    const isMobile = getIsMobile();
    const [inputValue, setInputValue] = useState("");
    // input
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        const time = moment(e.target.value, "HH:mm");
        props.onChange(time);
    };
    // TimePicker
    const handleTimePickerChange = (time: Moment) => {
        props.onChange(time);
    };
    // render
    return isMobile ? (
        <input
            {...props.field}
            onChange={handleInputChange}
            placeholder={"Date"}
            value={inputValue}
            step={300}
            type={"time"} className={"ant-input"} style={props.style}
        />
    ) : (
        <TimePicker
            {...props.field}
            onChange={handleTimePickerChange}
            minuteStep={5}
            style={props.style}
            format={"HH:mm"}
            placeholder={props.placeholder}
            defaultOpenValue={props.defaultOpenValue}
        />
    );
};

import React from "react";
import {Button, Col, Form, Row, Spin} from "antd";
import {FormItemWithValidation} from "../../offer/components/FormItemWithValidation";
import {Field, FormikProps} from "formik";
import {InputField} from "../../common/form-fields/InputField";
import {InputNumberField} from "../../common/form-fields/InputNumberField";
import {OfferLawyerType} from "../../firebase/fields/OfferLawyerType";
import {SelectField} from "../../common/form-fields/SelectField";
import {LawyerTypeOptions} from "../../offer/components/OfferFormOptions";
import {CheckboxField} from "../../common/form-fields/CheckboxField";


export interface IUserAccountForm {
    contactMail: string,
    contactPhone: string,
    lawyerType: OfferLawyerType | null,
    number: string,
    invoice: boolean;
    experience: number | null;
    facebookProfileUrl: string;
    linkedInProfileUrl: string;
}

interface IOwnProps {

}

export const UserAccountForm = (props: IOwnProps & FormikProps<IUserAccountForm>) => {
    const getFieldWrapperProps = (name: keyof IUserAccountForm) => ({
        error: props.errors[name],
        touched: props.touched[name]
    });

    const handleOnChange = (value: any, name: string) => {
        props.setFieldValue(name, value);
    };

    return (
        <Form>
            <Row>
                <Col>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("contactMail")}
                        label="Email kontaktowy"
                    >
                        <Field
                            name={"contactMail"}
                            onChange={(value: string) => handleOnChange(value, "contactMail")}
                            component={InputField}
                            placeholder={"@adwokatura.pl"}
                        />
                    </FormItemWithValidation>
                </Col>

                <Col>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("contactPhone")}
                        label="Telefon kontaktowy"
                    >
                        <Field
                            name={"contactPhone"}
                            onChange={(value: string) => handleOnChange(value, "contactPhone")}
                            component={InputNumberField}
                            placeholder={"+48 500 500 501"}
                            style={{width: "100%"}}
                            acceptsNull
                        />
                    </FormItemWithValidation>

                </Col>

            </Row>
            <Row>
                <Col>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("lawyerType")}
                        label="Profesja"
                    >
                        <Field
                            name={"lawyerType"}
                            onChange={(value: string) => handleOnChange(value, "lawyerType")}
                            component={SelectField}
                            options={LawyerTypeOptions}
                            placeholder={"Adwokat"}
                        />
                    </FormItemWithValidation>
                </Col>

                <Col>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("number")}
                        label="Numer Wpisu"
                    >
                        <Field
                            name={"number"}
                            onChange={(value: string) => handleOnChange(value, "number")}
                            component={InputField}
                            placeholder={"XYZXYZXYZ"}
                        />
                    </FormItemWithValidation>
                </Col>

                <Col span={8}>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("invoice")}
                        label="Faktura VAT"
                    >
                        <Field
                            name={"invoice"}
                            onChange={(value: string) => handleOnChange(value, "invoice")}
                            component={CheckboxField}
                            options={LawyerTypeOptions}
                        />
                    </FormItemWithValidation>
                </Col>

                <Col span={14} offset={2}>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("experience")}
                        label="Lata doświadczenia"
                    >
                        <Field
                            name={"experience"}
                            onChange={(value: string) => handleOnChange(value, "experience")}
                            component={InputNumberField}
                            style={{width: "100%"}}
                            placeholder={"5"}
                            acceptsNull
                        />
                    </FormItemWithValidation>
                </Col>

                  <Col span={24}>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("facebookProfileUrl")}
                        label="Link do profilu na Facebooku"
                    >
                        <Field
                            name={"facebookProfileUrl"}
                            onChange={(value: string) => handleOnChange(value, "facebookProfileUrl")}
                            component={InputField}
                            placeholder={"https://facebook.com/substytucje"}
                        />
                    </FormItemWithValidation>
                </Col>
                <Col span={24}>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("linkedInProfileUrl")}
                        label="Link do profilu na LinkedIn"
                    >
                        <Field
                            name={"linkedInProfileUrl"}
                            onChange={(value: string) => handleOnChange(value, "linkedInProfileUrl")}
                            component={InputField}
                            placeholder={"https://linkedin.com/substytucje"}
                        />
                    </FormItemWithValidation>
                </Col>

                <Col span={24} style={{textAlign: "center"}}>
                    <Button type="primary" style={{marginTop: 15}} onClick={() => props.handleSubmit()}
                            disabled={props.isSubmitting}>
                        <Spin spinning={props.isSubmitting}>
                            Zapisz
                        </Spin>
                    </Button>
                </Col>
            </Row>
        </Form>
    )
};

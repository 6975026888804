import React from "react";
import {Select} from "antd";
import {map} from "lodash";
import {FieldProps} from "formik";
import {ValueLabel} from "../interfaces";

interface IProps extends FieldProps<any>{
    placeholder: string;
    options: ValueLabel[];
    onChange: () => void;
    mode?: "multiple";
    notFoundContent?: string;
}


export const SelectField = (props: IProps) => {
    const {placeholder, options, onChange} = props;

    return (
        <Select {...props.field} mode={props.mode} placeholder={placeholder} onChange={onChange} notFoundContent={props.notFoundContent}>
            {map(options, (option) => {
                return (
                    <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>

                )
            })}
        </Select>
    )
}

import React from "react";
import {Button, Col, Icon, Row, Typography} from "antd";
import {LoginButton} from "../../auth/components/LoginButton";
import {appPath} from "../../app/utils/app_path";
import {Link} from "react-router-dom";
import logo from "../../images/logo.png"

const {Paragraph, Title} = Typography;

interface IProps {
    isAuthenticated: boolean;
    isAnonymous: boolean;
}

export const MainHomepageSection = (props: IProps) => {

    return (
        <Row style={{marginBottom: "5%"}}>
            <Col xs={{span: 24, offset: 0}}>
                <Col xs={{span: 24, offset: 0}} md={{span: 20, offset: 1}} lg={{span: 20, offset: 1}}>
                    <div style={{marginTop: 50}}>
                        <Title><img src={logo} height={40}/>SubstytucjeProcesowe</Title>
                    </div>
                </Col>
                <Col xs={{span: 21, offset: 1}} md={{span: 14, offset: 1}} lg={{span: 11, offset: 1}}>
                    <h2 style={{fontSize: 27}}>Znalezienie substytucji nigdy nie było
                        łatwiejsze</h2>
                    <Paragraph style={{marginTop: "5%"}}>
                        Od dziś znalezienie zastępstwa do sądu, prokuratury, czy policji zajmie Ci tylko chwilę.
                        Bądź na bieżąco dzięki indywidualnie wysyłanym powiadomieniom!
                        Sprawdź Substytucje Procesowe!
                    </Paragraph>

                    {props.isAnonymous && (
                        <>
                            <Row>
                                <Title level={3} style={{textAlign: "center"}}>Szukam: </Title>
                            </Row>
                            <Row>
                                <Col xs={{span: 11, offset: 1}} sm={{span: 11, offset: 1}}>
                                    <LoginButton text={"Substytuta"} hash={"#substytuta"}/>
                                </Col>
                                <Col xs={{span: 11, offset: 1}} sm={{span: 11, offset: 1}}>
                                    <LoginButton text={"Zleceń"} hash={"#zlecen"}/>
                                </Col>

                            </Row>
                        </>
                    )}

                    {props.isAuthenticated && (
                        <Row type={"flex"} style={{marginTop: 50}}>
                            <Link to={appPath.offer.list}>
                                <Button type={"danger"} size={"large"} style={{height: 50, maxWidth: 300, fontSize: 24, fontWeight: 800}}>
                                    Zobacz oferty
                                </Button>
                            </Link>
                        </Row>
                    )}
                </Col>
            </Col>
        </Row>
    )
};

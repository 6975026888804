import React from "react";
import {Button, Col, notification, Popconfirm, Row, Typography} from "antd";
import {map} from "lodash";
import {UserPreference} from "./UserPreference";
import {IUserPreference} from "../../firebase/fields/IUserPreference";
import {removePreference} from "../../user/actions/update_user_actions";
import {Messages} from "../../app/constants/notification_messages";
import {getOfferTypeDisplayNamePlural} from "../../firebase/fields/OfferType";
import {offerRegionMapWhere} from "../../firebase/fields/OfferRegion";
import firebase from "firebase/app";
import {IUser} from "../../firebase/documents/IUser";


interface IProps {
    user: IUser | null;
    preferences: IUserPreference[];
    setPreferences: (preferences: any) => void;
    applicationsIds: string[];
}
const {Title} = Typography;

export const UserPreferencesSection = (props: IProps) => {
    const onPreferenceRemove = (preference: IUserPreference) => {
        props.user && (
            removePreference(props.user.uid!, props.preferences, preference).then(newPreferences => {
                notification.success(Messages.preference.delete);
                return props.setPreferences(newPreferences);
            })
        )
    };

    const stopPropagation = (e: any) => {
        e.stopPropagation();
    };
    const renderHeader = (preference: IUserPreference) => {
        return (
            <Row style={{display: "flex", paddingTop: "2%"}}>
                <Col span={23} offset={1} style={{display: "inline"}}>
                    <Title level={2} style={{
                        display: "inline",
                        fontWeight: 500
                    }}>
                        {getOfferTypeDisplayNamePlural(preference.type)} {offerRegionMapWhere[preference.region]}
                    </Title>
                    <span style={{float: "right", paddingRight: "4%"}}>
                        <Popconfirm
                            title={Messages.preference.confirm}
                            onConfirm={(e) => {
                                stopPropagation(e);
                                onPreferenceRemove(preference);
                            }}
                            onCancel={stopPropagation}
                            okText="Tak"
                            cancelText="Nie"
                        >
                            <Button type="ghost" size="small" onClick={stopPropagation}>Usuń Listę</Button>
                        </Popconfirm>
                    </span>
                </Col>
            </Row>
        );
    };
    return (
        <>
            {map(props.preferences, (preference: IUserPreference, index: number) => {
            return (
                <div key={`${preference.region}-${preference.type}-key`} id={`${preference.region}-${preference.type}-anchor`}>
                    {renderHeader(preference)}
                    <UserPreference region={preference.region} type={preference.type} user={props.user} applicationsIds={props.applicationsIds}/>
                </div>
            )
        })}
        </>
    )
};

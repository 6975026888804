import React from "react";
import {FAQItem} from "./FAQItem";
import {Col, Row, Typography} from "antd";

const {Title, Paragraph} = Typography;

export const FAQSection = () => {
    return (

        <Row>
            <Col span={24} style={{textAlign: "center"}}>
                <Title level={2}> Najczęściej zadawane pytania</Title>
            </Col>
            <Col span={22} offset={1}>
                 <FAQItem
                question={`1. Na jakiej zasadzie to działa?`}
                description={<Paragraph>
                    Cała magia aplikacji opiera się o indywidualnie wysyłane przez przeglądarkę powiadomienia.
                    Jeśli chcesz w pełni korzystać z możliwości SubstytucjeProcesowe - konieczne jest, aby zezwolić przeglądarce wysyłać powiadomienia.
                </Paragraph>}
            />
            <FAQItem
                question={`2. Mieszkam w Warszawie a substytucji szukam w Krakowie. Czy znajdę tu zastępstwo?`}
                description={<Paragraph>
                    Oczywiście! Wystarczy, że tworząc ogłoszenie wybierzesz interesujące Cię miasto, a odpowiedni
                    kandydaci zostaną o tym automatycznie poinformowani.
                </Paragraph>}
            />
            <FAQItem
                question={"3. Chciałbym znaleźć ofertę w swoim regionie"}
                description={<Paragraph>
                    Wystarczy, że raz dodasz listę ze swoim miastem i gotowe! Zobaczysz wszystkie dostępne oferty,
                    a o nowych będziesz na bieżąco informowany wygodnymi powiadomieniami.
                </Paragraph>}
            />
            <FAQItem
                question={"4. Czy wszyscy widzą moje dane?"}
                description={<Paragraph>
                    Aby korzystać z portalu, trzeba być zalogowanym. Wtedy widoczne są tak zwane dane "publiczne".
                    Prywatne - m.in. dane kontaktowe, czy inne dane wrazliwe, przekazujemy tylko wybranemu przez Ciebie
                    pełnomocnikowi.
                </Paragraph>}
            />
            <FAQItem
                question={"5. Czy rejestracja jest czasochłonna?"}
                description={<Paragraph>
                    Bynajmniej. Rejestracja przez Social media skraca proces do minimum. A samo korzystanie z portalu
                    jest proste i intuicyjne.
                </Paragraph>}
            />
            </Col>
        </Row>
    )
}

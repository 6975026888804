import {firestoreDb} from "../../firebase/initialize_firebase_app";
import {collectionPath} from "../../firebase/collection_path";
import {IUser} from "../../firebase/documents/IUser";
import {waitForData} from "../../firebase/actions/wait_for_data";


export const fetchUser = async (userId: string): Promise<IUser> => {
    const userRef = firestoreDb.collection(collectionPath.users).doc(userId);
    // we need to wait for user-profile creation because after first login it may not exists yet
    return waitForData("fetchUser")<IUser>(userRef, data => !!data)
        .catch(e => {
            console.error("fetchUser", userId, e);
            throw e;
        });
};


import React, {useEffect, useMemo, useState} from "react";
import {Redirect, Route, Switch} from "react-router";
import firebase from "firebase/app";
import {Location} from "history";
import {HomepageView} from "../homepage/views/HomepageView";
import {DashboardView} from "../dashboard/views/DashboardView";
import {OfferDetailView} from "../offer/views/OfferDetailView";
import {OfferAddView} from "../offer/views/OfferAddView";
import {ApplicationView} from "../application/views/ApplicationView";
import {LoginView} from "../auth/views/LoginView";
import {OfferEditView} from "../offer/views/OfferEditView";
import {appPath} from "./utils/app_path";
import {NotFoundView} from "./NotFoundView";
import {FAQView} from "../homepage/views/FAQView";
import {PrivacyView} from "../homepage/views/PrivacyView";
import {TOSView} from "../homepage/views/TOSView";
import {usePrevious} from "./hooks/use_previous";
import {AboutUsView} from "../homepage/views/AboutUsView";
import {UserAccountView} from "../user/views/UserAccountView";
type User = firebase.User;


enum RouteViewType {
    STANDARD = "standard",
    SITE_404 = "404"
}

export const RouteViewTypeContext = React.createContext<{enableSite404: () => void}>({enableSite404: () => undefined});

interface IProps {
    location: Location;
    userData: User | null;
    isAuthPending: boolean;
    isAuthenticated: boolean;
    isAnonymous: boolean;
}

export const BodyRoutes = (props: IProps) => {

    /**
     * Handle route view type
     */
    const key = props.location.key;
    const prevKey = usePrevious(key, "");
    const [viewType, setViewType] = useState(RouteViewType.STANDARD);
    useEffect(() => {
        if (key !== prevKey) {
            setViewType(RouteViewType.STANDARD);
            window.scrollTo(0, 0);
        }
    }, [key, prevKey]);
    const viewTypeContext = useMemo(() => ({
        enableSite404: () => setViewType(RouteViewType.SITE_404)
    }), []);

    /**
     * Route view special cases
     */

    if (viewType === RouteViewType.SITE_404) {
        return (
            <Route render={(routeProps) => {
                return <NotFoundView {...routeProps} />;
            }}/>
        );
    }

    /**
     * Standard route view
     */

    return (
        <RouteViewTypeContext.Provider value={viewTypeContext}>
            <Switch>
                <Route path={appPath.homepage} exact strict sensitive render={(routeProps) => {
                    // if (isAuthenticated) {
                    //     return <Redirect to={appPath.offer.list}/>;
                    // }
                    return <HomepageView {...routeProps} isAuthenticated={props.isAuthenticated} isAnonymous={props.isAnonymous}/>;
                }}/>

                {/*// TODO any better way to handle oferty without / at the end? without it- it wrongly links to detail.  */}
                <Route path={"/oferty"} exact strict sensitive render={() => {
                      if (props.isAnonymous) {
                        return <Redirect to={appPath.homepage}/>;
                    }
                    return <Redirect to={appPath.offer.list}/>;
                }}/>

                <Route path={appPath.offer.list} exact strict sensitive render={routeProps => {
                    if (props.isAnonymous) {
                        return <Redirect to={appPath.homepage}/>;
                    }
                    return <DashboardView {...routeProps} />;
                }}/>
                <Route path={appPath.offer.detail.base} exact sensitive render={routeProps => {
                    return <OfferDetailView {...routeProps} isAuthPending={props.isAuthPending} isAnonymous={props.isAnonymous}/>;
                }}/>
                <Route path={appPath.offer.add} exact strict sensitive render={(routeProps) => {
                    if (props.isAnonymous) {
                        return <Redirect to={appPath.homepage}/>;
                    }
                    return <OfferAddView {...routeProps}/>
                }}/>
                <Route path={appPath.offer.detail.edit}  sensitive render={routeProps => {
                    return <OfferEditView {...routeProps}/>;
                }}/>
                {/*<Route path={appPath.offer.detail.author} exact strict sensitive render={(routeProps) => {*/}
                    {/*return <OfferAuthorView {...routeProps}/>;*/}
                {/*}}/>*/}
                <Route path={appPath.offer.detail.application.detail.base} exact strict sensitive render={(routeProps) => {
                    return <ApplicationView {...routeProps}/>;
                }}/>
                <Route path={appPath.login.base} exact strict sensitive render={() => {
                    return <LoginView userData={props.userData} isAnonymous={props.isAnonymous} successUrl={`/oferty/`}/>;
                }}/>
                <Route path={appPath.faq} render={(routeProps) => {
                    return <FAQView {...routeProps}/>;
                }}/>
                <Route path={appPath.privacy} render={() => {
                    return <PrivacyView/>;
                }}/>
                <Route path={appPath.tos} render={() => {
                    return <TOSView/>;
                }}/>
                <Route path={appPath.account} render={(routeProps) => {
                    return <UserAccountView {...routeProps}/>;
                }}/>
                <Route path={appPath.about} render={() => {
                    return <AboutUsView/>;
                }}/>
                <Route render={(routeProps) => {
                    return <NotFoundView {...routeProps} />;
                }}/>
            </Switch>
        </RouteViewTypeContext.Provider>
    );
};

import firebase from "firebase/app";
type DocumentReference = firebase.firestore.DocumentReference;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;


const DEFAULT_WAIT_TIMEOUT = 15000;

export const waitForData = (info: string, timeout = DEFAULT_WAIT_TIMEOUT) =>
    <TData>(docRef: DocumentReference, validate: (data: TData) => boolean) =>
        new Promise<TData>((resolve, reject) => {
            const timeoutHandle = setTimeout(() => reject(new Error(`waitForData [${info}]: exceeding timeout ${timeout}`)), timeout);
            const unsubscribe = docRef.onSnapshot((doc: DocumentSnapshot) => {
                const data = doc.data() as TData | undefined;
                if (data && validate(data)) {
                    clearTimeout(timeoutHandle);
                    unsubscribe();
                    return resolve(data as TData);
                }
            }, (error: Error) => {
                console.error(`waitForData [${info}]`, docRef.id, error);
                return reject(error);
            });
        });

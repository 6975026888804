import React from 'react';
import {Button} from "antd";
import {Link} from "react-router-dom";


interface IProps {
    text: string;
    hash?: string;
}

export const LoginButton = (props: IProps) => {
    return (
        <Link to={`/zaloguj/${props.hash ? props.hash : ""}`}>
            <Button type={"danger"} style={{fontSize: 21, fontWeight: 800, height: 50, width: "100%", paddingRight: 0, paddingLeft: 0 }}>
                {props.text}
            </Button>
        </Link>
    );
};

import {Dictionary, isPlainObject, isString, mapValues} from "lodash";


export const appPath = prefixPath("/", {
    homepage: "",
    offer: prefixPath("oferty/", {
        list: "",
        detail: prefixPath(":offerId([A-Za-z0-9]{20})/", {
            author: "autor/",
            base: "",
            edit: "edycja/",
            application: prefixPath("zgloszenia/", {
                detail: prefixPath(":applicationId([A-Za-z0-9]{20,28})/", {
                    base: "",
                })
            })
        }),
        add: "nowa-oferta/"
    }),
    login: prefixPath("zaloguj/", {
        base: "",
        tos: "regulamin",
        privacy: "prywatnosc"
    }),
    faq: "faq/",
    tos: "regulamin",
    about: "o-nas",
    privacy: "prywatnosc",
    account: "konto/"

});

/**
 * Helper
 */
export function prefixPath<T extends Dictionary<any>>(prefix: string, paths: T): T {
    return mapValues(paths, path => {
        if (isString(path)) {
            return prefix + path;
        }
        if (isPlainObject(path)) {
            return prefixPath(prefix, path);
        }
        throw new Error(`path has to be string or object: ${path}`);
    }) as any;
}

import React, {useContext, useEffect, useState} from "react";
import {Button, Icon, Modal, notification, Result} from "antd";
import {includes, map} from "lodash";
import {AddApplication, IApplicationForm} from "../../application/components/AddApplication";
import {ApplicationItem} from "../../application/components/ApplicationItem";
import {IOfferDetailOffer} from "../actions/fetch_offer_detail";
import {fetchApplications, IApplicationListApplication} from "../../application/actions/fetch_applications";
import {IApplicationCreate, IApplicationFormJson} from "../../firebase/documents/IApplication";
import {ApplicationStatus} from "../../firebase/fields/ApplicationStatus";
import {createApplication} from "../../application/actions/create_application";
import {Messages} from "../../app/constants/notification_messages";
import {OfferStatus} from "../../firebase/fields/OfferStatus";
import {applicationPluralize} from "../../application/constants/application_pluralize";
import {ApplicationsList} from "../../application/components/ApplicationsList";
import {UserProfileContext} from "../../app/Layout";
import {IUser} from "../../firebase/documents/IUser";
import {Link} from "react-router-dom";
import {appPath} from "../../app/utils/app_path";
import {getLawyerTypeDisplayName, yearsPluralize} from "../../firebase/fields/OfferLawyerType";

interface IProps {
    userId: string;
    offer: IOfferDetailOffer;
    fetchPrivateOfferDetail: () => void;
    userPhone: string | null;
    userEmail: string | null;
    renderAsSubmitButton?: boolean;
    hash?: string;
    refetchOfferDetail: () => void;
}


export const OfferDetailApplications = (props: IProps) => {
    const {offer} = props;
    const [isOpen, setOpen] = useState(false);
    const [isFetched, setFetched] = useState(false);
    const isAuthor = props.userId === offer.user;
    const isOfferActive = offer.status === OfferStatus.PUBLISHED;
    const [applications, setApplications] = useState<IApplicationListApplication[]>([]);
    const {userProfile} = useContext(UserProfileContext);
    const handleFetchApplications = async () => {
        // offer author fetches all applications to given offer, but applicant fetches only its own applications to given offer
        const offerApplications = await (isAuthor ? fetchApplications(offer.id) : fetchApplications(offer.id, props.userId));
        setFetched(true);
        setApplications(offerApplications);
    };

    //fetch applications only if viewed by author
    useEffect(() => {
        (async () => {
            if (offer) {
                handleFetchApplications();
            }
        })();
    }, [offer]);

    useEffect(() => {
        if (isAuthor) {
            props.fetchPrivateOfferDetail();
        }
    }, []);

    useEffect(() => {
        if (props.hash === "#apply") {
            if (!isAuthor && isFetched && !applications.length) {
                const errors = userProfile && validateApplicationAgainstOffer(userProfile, offer);
                if (errors != null && errors.length > 0) {
                    return;
                }
                setOpen(true);
            }
        }
    }, [isFetched]);

    const handleAddApplication = async (values: IApplicationForm, setSubmitting: (isSubmitting: boolean) => void) => {
        const applicationFormJson: IApplicationFormJson = {
            contactMail: values.email,
            contactPhone: values.phone.toString(),
            description: values.description,
            price: values.price
        };
        const applicationCreate: IApplicationCreate = {
            ...applicationFormJson,
            user: props.userId,
            status: ApplicationStatus.CREATING
        };
        const res = await createApplication(props.userId, offer.id, applicationCreate);
        if (res) {
            setApplications(prev => [res, ...prev]); // `prev` is an empty array anyway
            notification.success(Messages.application.add);
            setOpen(false);
        } else {
            notification.error({message: "Błąd!", description: "Zgłoszenie nie powiodło się."});
        }
        setSubmitting(false);

    };

    const validateApplicationAgainstOffer = (user: IUser, offer: IOfferDetailOffer): string[] => {
        const errors: string[] = [];
        if (offer.lawyerTypes.length > 0) {
            if (user.lawyerType == null) {
                errors.push("Profesja");
            }
            else if (!includes(offer.lawyerTypes, user.lawyerType)) {
                errors.push(map(offer.lawyerTypes, type => getLawyerTypeDisplayName(type)).join(" lub "));
            }
        }
        if (offer.numberRequired && !user.number) {
            errors.push("Nr Wpisu");
        }
        if (offer.experienceRequired != null) {
            if (user.experience == null) {
                errors.push("Lata doświadczenia");
            }
            else if (offer.experienceRequired > user.experience) {
                errors.push(`Wymagane ${offer.experienceRequired} ${yearsPluralize(offer.experienceRequired)} doświadczenia`);
            }
        }
        if (offer.invoiceRequired && !user.invoice) {
            errors.push("Faktura")
        }
        if (offer.facebookRequired && !user.facebookProfileUrl) {
            errors.push("Link do Facebook'a")
        }
        if (offer.linkedInRequired && !user.linkedInProfileUrl) {
            errors.push("Link do LinkedIn'a")
        }
        return errors;
    };

    const renderSubmitButton = () => {
        const errors = userProfile && validateApplicationAgainstOffer(userProfile, offer);
        return !isAuthor ? (
            <div style={{paddingBottom: "5%"}}>
                <Button type="primary" className={"center-below-768"} onClick={() => setOpen(true)} disabled={errors != null && errors.length > 0}>
                    Wyślij zgłoszenie
                </Button>
                <Modal
                    title="Wyślij zgłoszenie"
                    visible={isOpen}
                    footer={null}
                    onCancel={() => setOpen(false)}
                >
                    <AddApplication userPhone={props.userPhone} userEmail={props.userEmail}
                                    onSubmit={handleAddApplication} offerPrice={offer.price}/>
                </Modal>
                {errors != null && errors.length > 0 && (
                    <Result
                        style={{paddingTop: 10}}
                        status="error"
                        title="Autor ogłoszenia określił wymagania dotyczace tej oferty."
                        subTitle={<><div>Obecnie Twój profil nie posiada wymaganych informacji.</div>
                            <div> Uzupełnij profil, aby móc zaaplikować :)</div></>}
                        extra={[
                            <Link to={appPath.account} key={"profile"}>
                                <Button type="primary">
                                    Uzupelnij profil
                                </Button>
                            </Link>,
                            <Link to={appPath.offer.list} key={"dashboard"}>
                                <Button>Znajdź inną ofertę</Button>
                            </Link>
                        ]}
                    >
                        <div className="desc" style={{marginTop: 0}}>
                            {map(errors, err => (
                                <span key={err} style={{paddingRight: 10, display: "inline-block", whiteSpace: "nowrap"}}>
                                    <Icon style={{color: 'red'}} type="close-circle"/>  {err}
                                </span>
                            ))}
                        </div>
                    </Result>
                )}
            </div>
        ) : null
    };


    return offer ? (
        <>
            {!!offer.applicationCount &&
            <span><Icon
                type={"read"}/> Użytkownicy wysłali {offer.applicationCount} {applicationPluralize(offer.applicationCount)} </span>}

            {(!isAuthor) ? (
                (!applications.length && isOfferActive) ? (
                    // User nie jest autorem i Nie wyslal jeszcze zgloszenia
                    renderSubmitButton()
                ) : isOfferActive && (
                    // User nie jest autorem i Wyslal juz zgloszenie
                    <>
                        <br/>
                        <span>Wysłałeś już zgłoszenie na tę ofertę</span>
                        <ApplicationItem application={applications[0]} offerPrice={offer.price} offerId={offer.id}
                                         fetchPrivateOfferDetail={props.fetchPrivateOfferDetail} showContactData/>
                    </>
                )
            ) : (
                // User jest autorem
                <ApplicationsList offerStatus={offer.status} offerPrice={offer.price} applications={applications}
                                  offerId={offer.id}
                                  onSuccessfulAccept={() => {
                                      handleFetchApplications();
                                      props.refetchOfferDetail();
                                  }} showContactData
                />
            )}
        </>
    ) : null;
}

import {messaging} from "../../firebase/initialize_firebase_app";
import {saveDeviceToUser} from "../../user/actions/update_user_actions";
import {safelyRequestPermission} from "./init_firebase_messaging_listener";


export const updateFirebaseMessagingDevices = (userId: string, devices: string[]) => {
    // we need save device token to subscribe it to given topics (city, type)
    safelyRequestPermission().then(permission => {
        permission && messaging && messaging.getToken()
            .then((currentToken) => {
                if (currentToken) {
                    saveDeviceToUser(userId, devices, currentToken);
                }
            })
            .catch(e => {
                console.error("updateFirebaseMessagingDevices, getToken", userId, e);
                throw e;
            });
    });
};

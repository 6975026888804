import React from "react";
import {Anchor, Card, Col, Icon, Typography} from "antd";


interface IProps {
    title: string;
    iconType: string;
    description: string;
    // still thinking about moving somewhere on the page, explaining how the particular pieces look like.
    footerAnchorLink: string;
}

const {Link} = Anchor;
const {Title} = Typography;

export const BenefitItem = (props: IProps) => {
    return (
        <Col md={{span: 8, offset: 0}} xs={{span: 22, offset: 1}} style={{padding: 2}}>
            <Card hoverable style={{border: "none", display: "flex"}} bodyStyle={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Icon type={props.iconType} style={{fontSize: 100, color: "#626d8e"}}/>
                <br/>
                <Title level={2}>{props.title}</Title>
                <div className={"benefit-description"} style={{textAlign: "center"}}>{props.description}</div>
                <br/>
                {props.footerAnchorLink && <Link href={props.footerAnchorLink} title={"Czytaj więcej"}/>}
            </Card>
        </Col>
    )
}

// 4 columns layout
{/*<Col md={{span: 6, offset: 0}} sm={{span: 12, offset: 0}} xs={{span: 22, offset: 1}} style={{padding: 2}}>*/
}

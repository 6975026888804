import React, {useContext} from "react";
import {UserDetail} from "../components/UserDetail";
import {Formik, FormikActions, FormikErrors, FormikProps} from "formik";
import {IUserAccountForm, UserAccountForm} from "../components/UserAccountForm";
import {Col, Layout, notification, Row, Typography} from "antd";
import {UserProfileContext} from "../../app/Layout";
import {containerGrid} from "../../offer/style.utils";
import {updateUserProfile} from "../actions/update_user_actions";
import {RouteComponentProps} from "react-router";
import {fetchUser} from "../actions/fetch_user_actions";
import {Messages} from "../../app/constants/notification_messages";
import {UserMissingFields} from "../components/UserMissingFields";

interface IProps extends RouteComponentProps {}

const {Content} = Layout;
const {Title} = Typography;
export const UserAccountView = (props: IProps) => {
    const {userProfile, setUserProfile} = useContext(UserProfileContext);
    if (!userProfile) {
        return null;
    }

    const initialValues: IUserAccountForm = {
        contactMail: userProfile.contactMail || "",
        contactPhone: userProfile.contactPhone || "",
        lawyerType: userProfile.lawyerType || null,
        number: userProfile.number,
        invoice: userProfile.invoice || false,
        experience: userProfile.experience || null,
        facebookProfileUrl: userProfile.facebookProfileUrl,
        linkedInProfileUrl: userProfile.linkedInProfileUrl
    };

    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    const submitValidation = (values: IUserAccountForm): FormikErrors<IUserAccountForm> => {
        const errors: FormikErrors<IUserAccountForm> = {};
        if (values.facebookProfileUrl && !urlRegex.test(values.facebookProfileUrl)) {
            errors.facebookProfileUrl = "Niepoprawny adres";
        }
        if (values.linkedInProfileUrl && !urlRegex.test(values.linkedInProfileUrl)) {
            errors.linkedInProfileUrl = "Niepoprawny adres";
        }
        return errors;
    };
    return userProfile && (
        <Layout style={{padding: "4%"}}>
            <Content>
                <Row>

                    <Col {...containerGrid} style={{marginTop: "2%"}}>
                        <Col xs={{span: 24}} md={{span: 12}}>
                            <Title level={3}>Twoje konto</Title>
                            <UserDetail
                                contactPhone={initialValues.contactPhone}
                                contactMail={initialValues.contactMail}
                                lawyerType={initialValues.lawyerType}
                                number={initialValues.number}
                                displayName={userProfile.displayName}
                                photoURL={userProfile.photoURL}
                                invoice={initialValues.invoice}
                                experience={initialValues.experience}
                                facebookProfileUrl={initialValues.facebookProfileUrl}
                                linkedInProfileUrl={initialValues.linkedInProfileUrl}
                            />
                            <br/>
                            <Title style={{fontSize: 18}}>Uzupełniony profil pozwoli Ci aplikować na więcej ofert:</Title>
                            <br/>
                            <UserMissingFields
                                contactMail={userProfile.contactMail}
                                contactPhone={userProfile.contactPhone}
                                lawyerType={userProfile.lawyerType}
                                number={userProfile.number}
                                invoice={userProfile.invoice}
                                experience={userProfile.experience}
                                facebookProfileUrl={userProfile.facebookProfileUrl}
                                linkedInProfileUrl={userProfile.linkedInProfileUrl}
                            />
                        </Col>
                        <Col xs={{span: 24}} md={{span: 11, offset: 1}} style={{marginTop: "12px"}}>
                        <Formik
                            initialValues={initialValues}
                            validate={submitValidation}
                            onSubmit={async (values: IUserAccountForm, actions: FormikActions<IUserAccountForm>) => {
                                actions.setSubmitting(true);
                                const finalValues = {
                                    ...values,
                                    contactPhone: values.contactPhone != null ? values.contactPhone.toString() : ""
                                };

                                const res = await updateUserProfile(userProfile!.uid, finalValues);
                                if (res) {
                                    notification.success(Messages.user.edit);
                                    const updatedUser = await fetchUser(userProfile!.uid);
                                    setUserProfile && setUserProfile(updatedUser);
                                } else {
                                    notification.error({
                                        message: "Błąd!",
                                        description: "Zapis danych nie powiódł się."
                                    });
                                }
                                actions.setSubmitting(false);
                            }}
                            render={(renderProps: FormikProps<IUserAccountForm>) =>
                                <UserAccountForm {...renderProps} />}
                        />
                    </Col>
                </Col>
            </Row>
            </Content>
        </Layout>
    )
};

import firebase from "firebase/app"; // WARNING: importing from `firebase/app` fails in `firebase.messaging.isSupported()`
import * as firebaseui from 'firebaseui';
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

const isProduction = process.env.REACT_APP_BUILD_ENV === "production";
const {firebaseConfig, webPushCertificateKey} = isProduction ? require("./firebase_config_production") : require("./firebase_config_staging");

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);
if (isProduction) {
    firebase.analytics();
}
// Initialize the FirebaseUI Widget using Firebase.
export const firebaseUiAuth = new firebaseui.auth.AuthUI(firebase.auth());
// Initialize Firestore
export const firestoreDb = firebase.firestore();
// Initialize Firebase Messaging
export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
messaging && messaging.usePublicVapidKey(webPushCertificateKey);

import React from "react";
import {Field, FormikProps} from "formik";
import {Button, Col, Form, Icon, Row, Spin, Tooltip} from "antd";
import moment from "moment";
import {IOfferForm} from "../models/IOfferForm";
import {InputField} from "../../common/form-fields/InputField";
import {SelectField} from "../../common/form-fields/SelectField";
import {DatePickerField} from "../../common/form-fields/DatePickerField";
import {TimePickerField} from "../../common/form-fields/TimePickerField";
import {TextAreaField} from "../../common/form-fields/TextAreaField";
import {CheckboxField} from "../../common/form-fields/CheckboxField";
import {LawyerTypeOptions, OfferTypeOptions} from "./OfferFormOptions";
import {FormItemWithValidation} from "./FormItemWithValidation";
import {colSizesTwoFirst, colSizesTwoFirstBigger, colSizesTwoSecond, colSizesTwoSecondSmaller} from "../style.utils";
import {InputNumberField} from "../../common/form-fields/InputNumberField";
import {LocationSearchInput} from "../../common/form-fields/LocationSearchInput";

interface IOwnProps {
    buttonText: string;
}

export const OfferDetailForm = (props: IOwnProps & FormikProps<IOfferForm>) => {
    const getFieldProps = (name: keyof IOfferForm) => ({
        name,
        onChange: (value: string) => handleOnChange(value, name)
    });

    const getFieldWrapperProps = (name: keyof IOfferForm) => ({
        error: props.errors[name],
        touched: props.touched[name],
    });


    const handleOnChange = (value: any, name: keyof IOfferForm) => {
        props.setFieldValue(name, value);
    };

    return (
        <Form style={{backgroundColor: "#fff", padding: "3% 2%"}}>
             <Row>
                <Col span={22} offset={1}>
                    <Tooltip title="Każdy zalogowany użytkownik będzie mógł zobaczyć te informacje w ofercie">
                        <Icon type="info-circle" style={{color: "red"}}/> <b>Szczegóły oferty</b>
                    </Tooltip>
                </Col>
            </Row>
            <Row>
                <Col {...colSizesTwoFirst}>
                    <Col span={24}>
                         <FormItemWithValidation
                            {...getFieldWrapperProps("type")}
                            label="Rodzaj usługi"
                            required
                        >
                            <Field
                                {...getFieldProps("type")}
                                component={SelectField}
                                placeholder="Wybierz rodzaj usługi"
                                options={OfferTypeOptions}
                            />
                        </FormItemWithValidation>
                    </Col>
                    <Col span={24}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("city")}
                            label="Miasto"
                            required
                        >
                            <Field
                                {...getFieldProps("city")}
                                placeholder="Wybierz miasto"
                                component={LocationSearchInput}
                            />
                        </FormItemWithValidation>
                    </Col>


                    <Col span={24}>
                        <Col xs={{span: 24}} sm={{span: 12}}>
                            <FormItemWithValidation
                                {...getFieldWrapperProps("date")}
                                label="Data"
                                required
                            >
                                <Field
                                    {...getFieldProps("date")}
                                    placeholder="Wybierz datę"
                                    style={{width: "100%"}}
                                    component={DatePickerField}
                                />
                            </FormItemWithValidation>
                        </Col>
                        <Col xs={{span: 24}} sm={{span: 10, offset: 2}}>
                            <FormItemWithValidation
                                {...getFieldWrapperProps("time")}
                                label="Godzina"
                            >
                                <Field
                                    {...getFieldProps("time")}
                                    placeholder="9:00"
                                    style={{width: "100%"}}
                                    defaultOpenValue={moment.utc("8:00", "HH:mm")}
                                    component={TimePickerField}
                                />
                            </FormItemWithValidation>
                        </Col>
                    </Col>

                </Col>
                <Col {...colSizesTwoSecond}>
                    <Col span={24} >
                        <FormItemWithValidation
                            {...getFieldWrapperProps("addressName")}
                            label="Nazwa placówki"
                            required
                        >
                            <Field
                                {...getFieldProps("addressName")}
                                component={InputField}
                                placeholder={"np. \"Sąd rejonowy\""}

                            />
                        </FormItemWithValidation>
                    </Col>
                    <Col span={24}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("addressDepartment")}
                            label="Wydział"
                        >
                            <Field
                                {...getFieldProps("addressDepartment")}
                                component={InputField}
                                placeholder={"Wydział Gospodarczy"}

                            />
                        </FormItemWithValidation>
                    </Col>
                    <Col span={24}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("addressStreet")}
                            label="Adres placówki"
                        >
                            <Field
                                {...getFieldProps("addressStreet")}
                                placeholder={"Ulica, nr budynku, sala"}
                                component={InputField}
                            />
                        </FormItemWithValidation>
                    </Col>

                    <Col span={24} className={"dn-above-768"} >
                        <FormItemWithValidation
                            {...getFieldWrapperProps("description")}
                            label="Opis"
                        >
                            <Field
                                {...getFieldProps("description")}
                                component={TextAreaField}
                                autosize={{minRows: 5, maxRows: 6}}
                                placeholder="Krótki opis zlecenia"

                            />
                        </FormItemWithValidation>
                    </Col>
                </Col>
            </Row>
            <Row>
             <Col offset={1} span={22} className={"dn-below-768"} >
                        <FormItemWithValidation
                            {...getFieldWrapperProps("description")}
                            label="Opis"
                        >
                            <Field
                                {...getFieldProps("description")}
                                component={TextAreaField}
                                autosize={{minRows: 2, maxRows: 5}}
                                placeholder="Krótki opis zlecenia"

                            />
                        </FormItemWithValidation>
                    </Col>
            </Row>
              <Row>
                <Col span={22} offset={1}>
                    <Tooltip title="Tylko kandydaci spełniający te wymagania będą mogli zaaplikować na ofertę">
                        <Icon type="info-circle" style={{color: "red"}}/> <b>Wymagania oferty</b>
                    </Tooltip>
                </Col>
              </Row>
            <Row>
                <Col {...colSizesTwoFirstBigger}>
                    <Col xs={{span: 24}} md={{span: 18}}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("lawyerTypes")}
                            label="Szukam"
                        >
                            <Field
                                {...getFieldProps("lawyerTypes")}
                                component={SelectField}
                                placeholder="Adwokat, Radca Prawny"
                                notFoundContent={"Nie znaleziono"}
                                mode={"multiple"}
                                options={LawyerTypeOptions}
                            />
                        </FormItemWithValidation>
                    </Col>
                    <Col className={"dn-below-768"} xs={{span: 24}} md={{span: 2, offset: 2}}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("numberRequired")}
                            label="Nr wpisu"
                        >
                            <Field
                                {...getFieldProps("numberRequired")}
                                component={CheckboxField}
                            />
                        </FormItemWithValidation>
                    </Col>
                    <Col md={{span:8}} xs={{span: 11, offset: 0}}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("experienceRequired")}
                            label="Doświadczenie"
                        >
                            <Field
                                {...getFieldProps("experienceRequired")}
                                component={InputNumberField}
                                placeholder="5 lat"
                                style={{width: "100%"}}

                            />
                        </FormItemWithValidation>
                    </Col>
                    <Col md={{span:8, offset:2}} xs={{span: 11, offset: 2}} >
                        <FormItemWithValidation
                            {...getFieldWrapperProps("price")}
                            label="Wynagrodzenie"
                        >
                            <Field
                                {...getFieldProps("price")}
                                placeholder="200 zł"
                                style={{width: "100%"}}
                                component={InputNumberField}
                            />
                        </FormItemWithValidation>
                    </Col>
                      <Col span={2} offset={2} className={"dn-below-768"}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("linkedInRequired")}
                            label="LinkedIn"
                        >
                            <Field
                                {...getFieldProps("linkedInRequired")}
                                component={CheckboxField}
                            />
                        </FormItemWithValidation>
                    </Col>

                </Col>
                <Col {...colSizesTwoSecondSmaller}>

                    <Col className={"dn-above-768"} xs={{span: 11}} md={{span: 4, offset: 2}}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("numberRequired")}
                            label="Nr wpisu"
                        >
                            <Field
                                {...getFieldProps("numberRequired")}
                                component={CheckboxField}
                            />
                        </FormItemWithValidation>
                    </Col>
                    <Col xs={{span:11, offset: 2}} md={{span: 22}}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("invoiceRequired")}
                            label="Faktura VAT"
                        >
                            <Field
                                {...getFieldProps("invoiceRequired")}
                                component={CheckboxField}
                                placeholder="Faktura"

                            />
                        </FormItemWithValidation>
                    </Col>
                    <Col xs={{span: 11, offset: 0}} md={{span: 22, offset: 2}}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("facebookRequired")}
                            label="Facebook"
                        >
                            <Field
                                {...getFieldProps("facebookRequired")}
                                component={CheckboxField}
                            />
                        </FormItemWithValidation>
                    </Col>
                      <Col xs={{span: 11, offset: 2}} md={{span: 22}} className={"dn-above-768"}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("linkedInRequired")}
                            label="LinkedIn"
                        >
                            <Field
                                {...getFieldProps("linkedInRequired")}
                                component={CheckboxField}
                            />
                        </FormItemWithValidation>
                    </Col>
                </Col>
            </Row>
            <Row>
                <Col span={22} offset={1}>
                    <Tooltip title="Poniższe informacje pokażemy tylko wybranemu przez Ciebie substytutowi">
                        <Icon type="info-circle" style={{color: "red"}}/> <b>Dane ukryte</b>
                    </Tooltip>
                </Col>
            </Row>
            <Row>
                <Col {...colSizesTwoFirst}>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("contactMail")}
                        label="Email kontaktowy"
                    >
                        <Field
                            {...getFieldProps("contactMail")}
                            placeholder="@gmail.com"
                            component={InputField}
                        />
                    </FormItemWithValidation>
                </Col>
                <Col {...colSizesTwoSecond}>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("contactPhone")}
                        label="Telefon kontaktowy"
                    >
                        <Field
                            {...getFieldProps("contactPhone")}
                            placeholder="500 500 501"
                            style={{width: "100%"}}
                            component={InputNumberField}
                            acceptsNull
                        />
                    </FormItemWithValidation>
                </Col>
                <Col span={22} offset={1}>
                        <FormItemWithValidation
                            {...getFieldWrapperProps("descriptionPrivate")}
                            label="Wiadomość do Substytuta"
                        >
                            <Field
                                {...getFieldProps("descriptionPrivate")}
                                component={InputField}
                                placeholder='Na wypadek poufnych informacji...'
                            />
                        </FormItemWithValidation>
                </Col>
            </Row>

            <Row type={"flex"} justify={"center"}>
                <Button type="primary" onClick={() => props.handleSubmit()} disabled={props.isSubmitting}>
                    <Spin spinning={props.isSubmitting}>
                        {props.buttonText}
                    </Spin>
                </Button>
            </Row>
        </Form>
    )
};


import {firestoreDb} from "../../firebase/initialize_firebase_app";
import {collectionPath} from "../../firebase/collection_path";
import {ApplicationStatus} from "../../firebase/fields/ApplicationStatus";
import {IApplicationDetail} from "./fetch_application_detail";
import {waitForData} from "../../firebase/actions/wait_for_data";
import {IApplication} from "../../firebase/documents/IApplication";


export const acceptApplication = (applicationId: string, offerId: string): Promise<IApplicationDetail | void> => {
    const docRef = firestoreDb.collection(collectionPath.offersApplications({offerId})).doc(applicationId);
    return docRef
        .update({
            status: ApplicationStatus.ACCEPTING
        })
        .then(async () => {
            const data = await waitForData("acceptApplication")<IApplication>(docRef, data => data.status === ApplicationStatus.ACCEPTED);
            return {...data, id: docRef.id} as IApplicationDetail;
        })
        .catch(e => {
            console.error("acceptApplication", applicationId, offerId, e);
            throw e;
        });
};

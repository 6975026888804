import {Button, Result} from "antd";
import * as React from "react";
import {appPath} from "./utils/app_path";

export const NotFoundView = (props: any) => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Przykro nam, podana strona nie istnieje."
            extra={<Button onClick={() => props.history.push(appPath.offer.list)} type="primary">Wróc do widoku z listami</Button>}
        />
    )
}

import React, {useContext, useEffect, useState} from "react";
import {Avatar, Col, Icon, Layout, Row, Typography} from "antd";
import {Link} from "react-router-dom";
import moment from "moment";
import {map} from "lodash";
import {
    fetchOfferDetail,
    fetchOfferPrivateDetail,
    IOfferDetailOffer,
    IOfferDetailOfferPrivate
} from "../actions/fetch_offer_detail";
import {containerGrid} from "../style.utils";
import {appLink} from "../../app/utils/app_link";
import {OfferDetailApplications} from "./OfferDetailApplications";
import {getOfferTypeDisplayName} from "../../firebase/fields/OfferType";
import {getOfferDetailStatusDisplayName, OfferStatus} from "../../firebase/fields/OfferStatus";
import {getLawyerTypeDisplayName} from "../../firebase/fields/OfferLawyerType";
import {RouteViewTypeContext} from "../../app/BodyRoutes";

const {Text} = Typography;

interface IProps {
    offerId: string;
    userId: string;
    userPhone: string | null;
    userEmail: string | null;
    hash?: string;
}

export const OfferDetail = (props: IProps) => {
    const [offer, setOffer] = useState<IOfferDetailOffer | null>(null);
    // const [wasCopied, setCopied] = useState(false);
    const [offerPrivate, setOfferPrivate] = useState<IOfferDetailOfferPrivate | null>(null);
    // fetch offerDetail
    const routeViewType = useContext(RouteViewTypeContext);

    const getOfferDetail = async () => {
         const offerDetail = await fetchOfferDetail(props.offerId);
            if (offerDetail == null) {
                routeViewType.enableSite404();
                return;
            }
            setOffer(offerDetail);
    }

    useEffect(() => {
            getOfferDetail()
    }, []);

    if (!offer) {
        return null;
    }

    const isAuthor = offer.user === props.userId;

    const fetchPrivateDetail = async () => {
        const privateDetail = await fetchOfferPrivateDetail(offer.id) as IOfferDetailOfferPrivate;
        setOfferPrivate(privateDetail);
    };

    // const offerUrl = `https://substytutcjeprocesowe.pl/oferty/${offer.id}/`;
    // const handleCopyClick = () => {
    //     setCopied(true);
    //     setTimeout(() => setCopied(false), 1000);
    // };
    // const handleFBShareClick = () => {
    //  window.FB.ui({
    //     method: 'share',
    //     href: offerUrl,
    // });
    // }
    const {city, addressName, type, description, addressStreet, addressDepartment, userDisplayName, userPhotoURL, lawyerTypes, price, invoiceRequired} = offer;
    const date = moment(offer.date.toDate());
    const time = offer.time && moment(offer.time.toDate());
    const statusObj = getOfferDetailStatusDisplayName(offer.status);
    const getCreateDate = () => {
        const date = moment(offer.createTime.toDate());
        return <span>Utworzono {date.format("DD.MM.YYYY")} </span>
    };
    return (
        <Layout>
            <Row style={{marginTop: "3%"}}>
                <Col {...containerGrid}>
                    {isAuthor && offer.status === OfferStatus.PUBLISHED && <Link to={`${appLink.offer.detail.base({offerId: props.offerId})}edycja`} style={{float: "right", marginBottom: "2%", fontSize: 16}}>Edytuj</Link>}
                </Col>
                <Col {...containerGrid} style={{backgroundColor: "#fff", padding: "2%"}}>
                    <Row style={{padding: "2%"}}>
                        <Col xs={{span: 15, offset: 1}}>
                            <Icon type={statusObj.name} style={{color: statusObj.color}}/>
                            <Text style={{padding: "2%", color: statusObj.color}}>
                                {statusObj.label}
                            </Text>
                        </Col>
                        <div style={{float: "right"}}>
                            {getCreateDate()}
                            {/*<Icon type={"facebook"} style={{fontSize: 24, color: "#1890FF", cursor: "pointer"}} onClick={handleFBShareClick}/>*/}
                            {/*<Popover content={"Skopiowano"} trigger="click"  visible={wasCopied}>*/}
                            {/*<CopyToClipboard text={offerUrl}*/}
                            {/*options={{message: "Skopiowano!", debug: true}} onCopy={handleCopyClick}>*/}
                            {/*<Icon component={() => <img src={send} height={20}/>} style={{fontSize: 24, color: "#1890FF", marginLeft: 5}}/>*/}
                            {/*</CopyToClipboard>*/}
                            {/*</Popover>*/}

                        </div>
                    </Row>
                    <Row style={{padding: "2%"}}>
                        <Col xs={{offset: 4, span: 20}} md={{span: 16, offset: 8}}>
                            <Col xs={{span: 20}} sm={{offset: 8, span: 14}} md={{span: 18, offset: 4}}>
                                <Text style={{fontWeight: 500, float: "right", paddingRight: "2%"}}>Dodane przez: {userDisplayName}</Text>
                            </Col>
                            <Col xs={{span: 4}} sm={{span: 2}}>
                                <Avatar src={userPhotoURL ? userPhotoURL : ""}/>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span: 24}} style={{
                            fontSize: 42,
                            fontWeight: 800,
                            textAlign: "center"
                        }}>{getOfferTypeDisplayName(type)} {city}</Col>
                    </Row>
                    <Row style={{padding: "2%"}}>
                        <Col xs={{span: 24}} style={{fontSize: 30, fontWeight: 800, textAlign: "center"}}>
                            {date.format("DD.MM.YYYY")} {time && ` - ${time.format("HH:mm")}`}
                        </Col>
                    </Row>
                    <Row style={{padding: "2% 0"}}>
                        <Col xs={{span: 22, offset: 1}} md={{span: 13, offset: 1}} className={"mobile-text-center"}>
                            <Col span={24} style={{fontSize: 20, fontWeight: 600}}>{addressName}</Col>
                            <Col span={24} style={{fontSize: 20, fontWeight: 600}}>{addressDepartment}</Col>
                            <Col span={24} style={{fontSize: 20, fontWeight: 600}}>{addressStreet}</Col>
                            <Col span={24} style={{fontSize: 18, paddingTop: "2%"}}>{description}</Col>

                        </Col>
                        <Col xs={{span: 22, offset: 1}} md={{span: 9, offset: 1}} className={"pad-top-2"}>
                            {!!lawyerTypes.length && (
                            <Col span={24} style={{fontSize: 16, textAlign: "center", marginBottom: "5%"}}>
                                <Icon type={"user"} style={{fontSize: 24}}/><Text style={{fontSize: 24}}>
                                Szukam: {map(lawyerTypes, t => <div key={t}>{getLawyerTypeDisplayName(t)}</div>)}
                            </Text>
                            </Col>
                        )}
                        {price && (
                            <Col span={24} style={{fontSize: 16, textAlign: "center", marginBottom: "5%"}}>
                                <Icon type={"dollar"} style={{fontSize: 24}}/><Text
                                style={{fontSize: 24}}> {price} zł</Text>
                            </Col>
                        )}
                        {invoiceRequired && (
                            <Col span={24} style={{fontSize: 16, textAlign: "center"}}>
                                <Icon type={"file"} style={{fontSize: 24}}/><Text style={{fontSize: 24}}>Faktura
                                VAT</Text>
                            </Col>
                        )}
                        </Col>
                    </Row>
                    {offerPrivate && (
                        <Row style={{padding: "2% 1%"}}>
                            <Col span={23} offset={1}>
                                <Col xs={{span: 24}} sm={{span: 16}}>
                                    {offerPrivate.contactMail && (
                                        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 16}}
                                             style={{paddingTop: "3%"}}>
                                            <a className="contact-detail" href={`mailto:${offerPrivate.contactMail}`}
                                               style={{fontSize: 18, color: "#626d8e"}}>
                                                <Icon type="mail"/> {offerPrivate.contactMail}
                                            </a>
                                        </Col>
                                    )}
                                    {offerPrivate.contactPhone && (
                                        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 8}}
                                             style={{paddingTop: "3%"}}>
                                            <a className="contact-detail" href={`tel:+${offerPrivate.contactPhone}`}
                                               style={{fontSize: 18, color: "#626d8e"}}>
                                                <Icon type="phone"/> {offerPrivate.contactPhone}
                                            </a>
                                        </Col>
                                    )}
                                </Col>
                                <Col span={24} style={{paddingTop: "2%"}}>{offerPrivate.descriptionPrivate}</Col>
                            </Col>
                        </Row>

                    )}
                </Col>
            </Row>
            <Row>
                <Col {...containerGrid} style={{paddingTop: "1%"}}>
                    {(offer as any).presentational && <Col style={{float: "right", color: "red"}}>*Ogłoszenie prezentacyjne</Col>}
                    <Col span={24}>
                    <OfferDetailApplications userId={props.userId} userPhone={props.userPhone}
                                             userEmail={props.userEmail} offer={offer}
                                             fetchPrivateOfferDetail={fetchPrivateDetail} hash={props.hash}
                                             refetchOfferDetail={getOfferDetail}
                    />
                    </Col>
                </Col>
            </Row>
        </Layout>
    )
};

import React, {useState} from "react";
import {DatePicker} from "antd";
import {FieldProps} from "formik";
import moment, {Moment} from "moment";
import {getIsMobile} from "../../app/utils/check_device";
import plPL from "moment/locale/pl";


interface IProps extends FieldProps<any> {
    placeholder: string;
    onChange: (value: Moment) => void;
    style?: any;
}

// this component is tested only for date picking, it is not ready for time picking
// we need to ensure that we handle all time communication with server using UTC time
export const DatePickerField = (props: IProps) => {
    const isMobile = getIsMobile();
    const today = moment();
    const future = moment().add(30, "days");
    // input
    const [inputValue, setInputValue] = useState("");
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInputValue(e.target.value);
        props.onChange(moment(e.target.value).startOf("day"));
    };
    // DatePicker
    const handleDatePickerChange = (date: Moment): void => {
        props.onChange(date.startOf("day"));
    };
    // render
    return isMobile ? (
        <input
            {...props.field}
            onChange={handleInputChange}
            placeholder={"Date"}
            value={inputValue}
            min={today.format("YYYY-MM-DD")}
            max={future.format("YYYY-MM-DD")}
            type={"date"} className={"ant-input"} style={props.style}
        />
    ): (
        <DatePicker
            locale={plPL}
            {...props.field} showTime={false} defaultValue={today}
            style={props.style}
            disabledDate={d => d == null || d.isBefore(today) || d.isAfter(future)}
            onChange={handleDatePickerChange}
            placeholder={props.placeholder}
        />
    );
};

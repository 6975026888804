import {pluralize} from "../../app/utils/pluralize";

export enum OfferLawyerType {
    ADWOKAT = 1,
    RADCA = 2,
    APLIKANT_ADWOKACKI = 3,
    APLIKANT_RADCOWSKI = 4
}

export const getLawyerTypeDisplayName = (lawyerType: OfferLawyerType) => {
    switch(lawyerType) {
        case OfferLawyerType.ADWOKAT:
            return "Adwokat";
        case OfferLawyerType.RADCA:
            return "Radca prawny";
        case OfferLawyerType.APLIKANT_ADWOKACKI:
            return "Aplikant adwokacki";
        case OfferLawyerType.APLIKANT_RADCOWSKI:
            return "Aplikant radcowski";
        default:
            throw new Error(`getLawyerType: unknown lawyerType: ${lawyerType}`);
    }
};

export const getExperienceDisplayName = (experience: number) => {
    return experience === 1 ? "rocznym" : `${experience} letnim`;
};

export const yearsPluralize = pluralize(["rok", "lata", "lat"]);

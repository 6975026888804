import * as React from "react";

interface IProps {
    children: JSX.Element;
}
export const CenterBox = (props: IProps) => {
    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>{props.children}</div>
    );
};

import React, {useContext, useEffect, useMemo, useState} from "react";
import {RouteComponentProps} from "react-router";

import {UserPreferencesSection} from "../components/UserPreferencesSection";
import {UseOwnOffersSection} from "../components/UserOwnOffersSection";
import {Button, Col, Empty, Icon, Layout, Modal, notification, Row, Spin, Tooltip, Typography} from "antd";
import {IUserPreference} from "../../firebase/fields/IUserPreference";
import {createPreference} from "../../user/actions/update_user_actions";
import {Messages} from "../../app/constants/notification_messages";
import {AddPreference} from "../components/AddPreference";
import {UserOwnApplicationsSection} from "../components/UserOwnApplicationsSection";
import {CenterBox} from "../../app/components/CenterBox";
import {IUserPreferenceForm} from "../components/AddPreferenceForm";
import {UserProfileContext} from "../../app/Layout";
import {fetchAllUserApplications, IApplicationListApplication} from "../../application/actions/fetch_applications";
import {map} from "lodash";

const {Content} = Layout;
const {Title} = Typography;

interface IProps extends RouteComponentProps {
}

export const DashboardView = (props: IProps) => {
    const {userProfile} = useContext(UserProfileContext);
    const [preferences, setPreferences] = useState<IUserPreference[]>([]);
    const [applications, setApplications] = useState<IApplicationListApplication[]>([]);

    useEffect(() => {
        (async () => {
            if (userProfile) {
                setPreferences(userProfile.preferences);
                const applications = await fetchAllUserApplications(userProfile.uid);
                setApplications(applications);
            }
        })();
    }, [userProfile]);

    const applicationsIds = useMemo(() => map(applications, (app) => app.offer), [applications]);

    const [isOpen, setOpen] = useState(false);
    const addPreference = (preference: IUserPreferenceForm, setSubmitting: (isSubmitting: boolean) => void) => {
        userProfile && createPreference(userProfile.uid, preferences, preference as IUserPreference).then(newPreferences => {
            notification.success(Messages.preference.add);
            setPreferences(newPreferences);
            setSubmitting(false);
            return setOpen(false);
        });
    };

     const renderListHeader = () => {
         const limitExcedeed = preferences.length >= 5;

         return (
             <div id={"moje-listy"} style={{marginLeft: "4%", display: "flex", flexWrap: "wrap", justifyContent: "flex-end", marginRight: "4%", marginTop: "1%"}}>
                     <Title style={{
                         fontWeight: 600,
                         marginRight: "auto"
                     }} level={2}>
                         Moje Listy
                     </Title>

                 <div >
                 {limitExcedeed ? (
                     <Tooltip placement="topLeft"
                              title="Można mieć maksymalnie 5 aktywnych list">
                         <Button type={"primary"}
                                 onClick={() => setOpen(true)}
                                 size={"large"} disabled={true}>
                             <Icon type={"plus-circle"}/>
                             Dodaj Listę
                         </Button>
                     </Tooltip>
                 ) : (
                     <Button type={"primary"} onClick={() => setOpen(true)}
                             size={"large"}>
                         <Icon type={"plus-circle"}/>
                         Dodaj Listę
                     </Button>
                 )}
                 </div>
             </div>
         )
     };

    return (
        userProfile ? (
            <Layout style={{backgroundColor: "#1890ff0f"}}>
                <Modal
                    onCancel={() => setOpen(false)}
                    title="Co Cię interesuje?"
                    visible={isOpen}
                    destroyOnClose
                    footer={[]}
                >
                    <AddPreference onSubmit={addPreference} userCurrentPreferences={preferences}/>
                </Modal>

                <Content style={{backgroundColor: "ddd"}}>
                    <section style={{padding: "2% 0"}}>
                        <UseOwnOffersSection userId={userProfile.uid}/>
                    </section>
                        <section>
                            <UserOwnApplicationsSection userId={userProfile.uid} applications={applications}/>
                        </section>
                    {renderListHeader()}
                    {preferences.length > 0 ? (
                        <section>
                            <UserPreferencesSection
                                user={userProfile}
                                preferences={preferences}
                                setPreferences={setPreferences}
                                applicationsIds={applicationsIds}
                            />
                        </section>
                    ) : (
                        <Row type="flex" justify={"center"} align={"middle"} style={{marginTop: "5%", paddingBottom: "5%"}}>
                            <Col xs={24}>
                                <Empty description={`Kliknij w "Dodaj Listy", aby dodać pierwszą listę ogłoszeń ;)`}/>
                            </Col>
                                <Button style={{marginTop: "1%",}} type={"primary"} onClick={() => setOpen(true)}>Dodaj Listy</Button>
                        </Row>

                    )}
            </Content>
        </Layout>
        ) : <CenterBox><Spin style={{fontSize: 400}} size={"large"} spinning={true}/></CenterBox>
    )
};

import firebase from "firebase/app";
import {firestoreDb} from "../../firebase/initialize_firebase_app";
import {collectionPath} from "../../firebase/collection_path";
import {IApplication} from "../../firebase/documents/IApplication";
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;

export interface IApplicationDetail extends IApplication {
    id: string;
}

export const fetchApplicationDetail = (applicationId: string, offerId: string): Promise<IApplicationDetail> => {
    return firestoreDb
        .collection(collectionPath.offersApplications({offerId}))
        .doc(applicationId)
        .get()
        .then((doc: DocumentSnapshot): IApplicationDetail => ({...doc.data() as IApplication, id: doc.id}))
        .catch(e => {
            console.error("fetchApplicationDetail", applicationId, offerId, e);
            throw e;
        });
};

import React from "react";
import {Button, Card, List, Skeleton, Typography} from "antd";
import {IOfferPreviewWithId} from "../../offer/actions/fetch_offer_list";
import {getOfferTypeDisplayName} from "../../firebase/fields/OfferType";

const {Text, Paragraph} = Typography;

interface IProps {
    offer: IOfferPreviewWithId;
    isLoading: boolean;
    onRedirectClick: (url: string) => void;
}

export const OfferPreviewListItem = (props: IProps) => {
    const {offer} = props;
    const linkUrl = `oferty/${offer.id}/`;

    const renderActionButton = () => {
        return <Button type={"link"}>Zobacz ofertę</Button>
    };
    const renderCardMeta = () => {
        return (
            <Card.Meta
                title={`${getOfferTypeDisplayName(offer.type)} ${offer.city}`}
            />
        )
    };

    return (
        <List.Item style={{height: "auto", width: "auto", overflow: "hidden"}}>
            <Skeleton loading={props.isLoading} active>
                <Card title={renderCardMeta()}
                      onClick={() => props.onRedirectClick(linkUrl)}
                      hoverable
                      actions={[renderActionButton()]}
                >
                    <Paragraph ellipsis={{rows: 1}}>
                        <Text style={{fontSize: 20}}>{offer.addressName}</Text>
                    </Paragraph>
                </Card>
            </Skeleton>
        </List.Item>
    )
};

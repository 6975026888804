import React from "react";
import {Card, Row} from "antd";
import {BenefitItem} from "./BenefitItem";

export const BenefitsSection = () => {
    return (
        <Card className={"always-border-hovered"}>
            <Row>
                 <BenefitItem
                    iconType={"home"}
                    title={"Precyzyjnie"}
                    description={"Możesz dokładnie określić czego wymagasz od potencjalnych kandydatów"}
                    footerAnchorLink={""}
                />
                <BenefitItem
                    iconType={"clock-circle"}
                    title={"Szybko"}
                    description={"Informujemy Cię od razu, gdy ktoś zaaplikuje na Twoje ogłoszenie"}
                    footerAnchorLink={""}
                />
                <BenefitItem
                    iconType={"schedule"}
                    title={"Konkretnie"}
                    description={"Nie wysyłamy mailowego spamu. Informujemy Cię notyfikacjami z aplikacji"}
                    footerAnchorLink={""}
                />
                {/*<BenefitItem*/}
                    {/*iconType={"mobile"}*/}
                    {/*title={"Mobilnie"}*/}
                    {/*description={"Serwis działa również w wersji mobilnej"}*/}
                    {/*footerAnchorLink={""}*/}
                {/*/>*/}
            </Row>
        </Card>
    )
};

export enum ApplicationStatus {
    // meta statuses
    ACCEPTING = "accepting",
    CREATING = "creating",
    // actual statuses
    ACCEPTED = "accepted",
    PENDING = "pending",
    REJECTED = "rejected"
}

interface StatusObj {
    name: "success" | "default" | "warning" | "error" | "processing",
    label: string;
}

export const getApplicationStatusDisplayName = (status: ApplicationStatus): StatusObj => {
    switch (status) {
        case ApplicationStatus.ACCEPTED:
            return {
                name: "success",
                label: "Zaakceptowane"
            };
        case ApplicationStatus.PENDING:
            return {
                name: "processing",
                label: "Wysłano"
            };
        case ApplicationStatus.REJECTED:
            return {
                name: "error",
                label: "Odrzucono"
            };
        case ApplicationStatus.ACCEPTING:
            return {
                name: "default",
                label: "W trakcie akceptacji"
            };
        case ApplicationStatus.CREATING:
            return {
                name: "default",
                label: "W trakcie tworzenia"
            };
        default:
            throw new Error("Nie okreslono statusu");
    }
};

export const firebaseConfig = {
    apiKey: "AIzaSyBhcH861ketUGKQSq9XK_cN33Kt3i05XVA",
    authDomain: "replacement-dev.firebaseapp.com",
    databaseURL: "https://replacement-dev.firebaseio.com",
    projectId: "replacement-dev",
    storageBucket: "replacement-dev.appspot.com",
    messagingSenderId: "1065984269781",
    appId: "1:1065984269781:web:cdf94a3a7de0eabbb7715c"
};

export const webPushCertificateKey = "BJvFtkTE5P_mA5ZXqNdZFa0ho8PWVPj2rC8MfjQrMkVjn0mpVGe37nIhIPIn63FmQqHw3y7QSiqwM8JcF3PuEQM";

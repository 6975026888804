import {useEffect, useRef} from "react";


export const usePrevious = <T>(value: T, initValue: T): T => {
    const ref = useRef<T>(initValue);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

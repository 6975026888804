import firebase from "firebase/app";
import {isEmpty} from "lodash";
import moment from "moment";
import {IOfferForm} from "./models/IOfferForm";
import {IOfferFormJson} from "../firebase/documents/IOffer";

const Timestamp = firebase.firestore.Timestamp;


// translates offer-document JSON data to offer-form data
export const fromJsonValues = (offer: IOfferFormJson): IOfferForm => {
    return {
        city: {
            name: offer.city,
            id: offer.cityId,
            region: offer.region
        },
        type: offer.type,
        addressName: offer.addressName,
        addressDepartment: offer.addressDepartment,
        addressStreet: offer.addressStreet,
        date: moment(offer.date.toDate()),
        time: offer.time ? moment(offer.time.toDate()) : null,
        lawyerTypes: offer.lawyerTypes,
        invoiceRequired: offer.invoiceRequired,
        experienceRequired: offer.experienceRequired,
        numberRequired: offer.numberRequired,
        facebookRequired: offer.facebookRequired,
        linkedInRequired: offer.linkedInRequired,
        price: offer.price,
        description: offer.description,
        contactMail: offer.contactMail,
        contactPhone: !isEmpty(offer.contactPhone) ? parseInt(offer.contactPhone, 10) : null,
        descriptionPrivate: offer.descriptionPrivate
    }
};

// translates offer-form data to offer-document JSON data
export const toJsonValues = (values: IOfferForm, onError: (field: string) => void): IOfferFormJson | null => {
    if (values.city == null) {
        onError("city");
        return null;
    }

    if (values.type == null) {
        onError("type");
        return null;
    }
    if (values.date == null) {
        onError("date");
        return null;
    }

    return {
        // client meta fields
        cityId: values.city.id,
        region: values.city.region,
        // fields
        city: values.city.name,
        type: values.type,
        addressName: values.addressName,
        addressDepartment: values.addressDepartment,
        addressStreet: values.addressStreet,
        date: Timestamp.fromDate(moment.utc(values.date.format("YYYY-MM-DD"), "YYYY-MM-DD").toDate()),
        time: values.time && Timestamp.fromDate(values.time.toDate()),
        lawyerTypes: values.lawyerTypes,
        invoiceRequired: values.invoiceRequired,
        experienceRequired: values.experienceRequired,
        numberRequired: values.numberRequired,
        facebookRequired: values.facebookRequired,
        linkedInRequired: values.linkedInRequired,
        price: values.price,
        description: values.description,
        contactMail: values.contactMail,
        contactPhone: values.contactPhone != null ? values.contactPhone.toString() : "",
        descriptionPrivate: values.descriptionPrivate
    }
};

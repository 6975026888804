import React from "react";
import {Avatar, Badge, Button, Card, Icon, List, Typography} from "antd";
import moment from "moment";
import {IOfferListOffer} from "../actions/fetch_offer_list";
import {Link} from "react-router-dom";
import {applicationPluralize} from "../../application/constants/application_pluralize";
import firebase from "firebase/app";
import {includes} from "lodash";
import {IUser} from "../../firebase/documents/IUser";

const {Text, Paragraph} = Typography;


interface IProps {
    offer: IOfferListOffer;
    user: IUser | null;
    applicationsIds: string[];
}

export const OfferListItem = (props: IProps) => {
    const {offer, applicationsIds} = props;
    const isAuthor = props.user && props.user.uid === offer.user;
    const hasUserApplied = includes(applicationsIds, offer.id);
    const renderCardMeta = () => {
        return (
            <Link to={offerLink}>
                <Card.Meta
                    avatar={
                        <Avatar size="large" src={offer.userPhotoURL ? offer.userPhotoURL : ""}/>
                    }
                    title={offer.userDisplayName}
                    description={<><Icon type={"read"}/>{offer.applicationCount ? <span>{` ${offer.applicationCount} ${applicationPluralize(offer.applicationCount)}`}</span> : <span> Brak zgłoszeń</span>}</>}
                />
            </Link>
        )
    };

    const renderFooter = () => {
        return !hasUserApplied ? (
            <Button type="primary" style={{height: "30px"}}>
                <Link to={`${offerLink}#apply`}>Wyślij zgłoszenie</Link>
            </Button>
        ) : <Link style={{height: "30px"}} to={`${offerLink}`}>Zgłoszenie już zostało wysłane</Link>
    };
    const renderExtra = () => {
        return (
            <div style={{height: "100%"}} className={"dn-below-520"}>
                <p style={{fontSize: 14, color: "#626d8e"}}>Utworzono {getCreateDate()}</p>
            </div>
        )
    };

    const getCreateDate = () => {
        const date = moment(offer.createTime.toDate());
        return date.format("DD.MM.YYYY")
    };

    const date = moment(offer.date.toDate());
    const time = offer.time && moment(offer.time.toDate());
    const offerLink = `${offer.id}/`;

    return (

            <List.Item style={{minHeight: 330}}>
                    <Card title={renderCardMeta()}
                          hoverable
                          style={{overflow: "hidden"}}
                          actions={!isAuthor ? [renderFooter()] : [<Badge style={{height: 30}} status={"success"} text={"Twoja oferta"}/>]}
                          bodyStyle={{height: 180}}
                          extra={renderExtra()}
                    >
                        <Link to={offerLink}>
                            <Paragraph ellipsis={{rows: 5}}>
                                <Text>{date.format("DD.MM.YYYY")}</Text>
                                {time && <Text style={{fontSize: 16}}> godz. {time.format("HH:mm")}</Text>}
                                <br/>
                                <Text>{offer.addressName}</Text>
                                <br/>
                                <Text>{offer.addressStreet}</Text>
                                <br/>
                                <Text>{offer.description}</Text>
                            </Paragraph>
                        </Link>
                    </Card>
            </List.Item>
    )
};

import React from "react";
import {Link} from "react-router-dom";
import {Layout} from "antd";
import {appPath} from "../utils/app_path";

const {Footer} = Layout;

export const FooterC = () => {
    return (
          <Footer id={"footer"}>
              <Link style={{paddingRight: "2%"}} to={appPath.tos}>Regulamin</Link>
              <Link style={{paddingRight: "2%"}} to={appPath.privacy}>Polityka Prywatności</Link>
              <Link style={{paddingRight: "2%"}} to={appPath.about}>O&nbsp;Nas</Link>
              <a href={"mailto: kontakt@substytucjeprocesowe.pl"}>Kontakt</a>
          </Footer>
    );
};


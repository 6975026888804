import React from "react";
import {map} from "lodash";

import {IUserPreference} from "../../firebase/fields/IUserPreference";
import {Formik, FormikActions, FormikErrors, FormikProps} from "formik";
import {OfferType} from "../../firebase/fields/OfferType";
import {AddPreferenceForm, IUserPreferenceForm} from "./AddPreferenceForm";
import {isOfferRegionValid} from "../../firebase/fields/OfferRegion";


interface IProps {
    onSubmit: (preference: IUserPreferenceForm, setSubmitting: (isSubmitting: boolean) => void) => void;
    userCurrentPreferences: IUserPreference[];
}
export const AddPreference = (props: IProps) => {
    const initialValues: IUserPreferenceForm = {
        region: "",
        type: OfferType.SUBSTITUTION,
    };

    const emptyFieldValidation = (values: IUserPreferenceForm) => {
        let errors: FormikErrors<IUserPreference> = {};

        if (!isOfferRegionValid(values["region"])) {
            errors["region"] = 'Pole wymagane';
        }
        map(props.userCurrentPreferences, (preference) => {
            if (preference.region === values["region"] && preference.type === values["type"]) {
                errors["region"] = 'Subskrybujesz już taką listę';
                errors["type"] = 'Subskrybujesz już taką listę';
            }
        });
        return errors;
    };


    return (
        <div>
            <Formik
                initialValues={initialValues}
                validate={emptyFieldValidation}
                onSubmit={(values: IUserPreferenceForm, actions: FormikActions<IUserPreferenceForm>) => {
                    actions.setSubmitting(true);
                    props.onSubmit(values, actions.setSubmitting);
                }}
                render={(renderProps: FormikProps<IUserPreferenceForm>) => <AddPreferenceForm {...renderProps} />}
            />
        </div>
    )
};


import React, {useContext} from "react";
import {OfferEdit} from "../components/OfferEdit";
import {RouteComponentProps} from "react-router";
import {Layout} from "antd";
import {UserProfileContext} from "../../app/Layout";


interface IProps extends RouteComponentProps<{offerId: string}> {}

export const OfferEditView = (props: IProps) => {
    const offerId = props.match.params.offerId;
    const {userProfile} = useContext(UserProfileContext);
    return (
        <Layout>
            {userProfile && <OfferEdit userId={userProfile.uid} offerId={offerId} redirectToOffer={() => props.history.push(`/oferty/${offerId}/`)}/>}
        </Layout>
    );
};

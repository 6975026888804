import React, {useEffect, useState} from "react";
import moment from "moment";
import {Avatar, Badge, Button, Card, Col, Icon, List, notification, Row, Spin, Typography} from "antd";
import {IApplicationListApplication} from "../actions/fetch_applications";
import {acceptApplication} from "../actions/accept_application";
import {ApplicationStatus, getApplicationStatusDisplayName} from "../../firebase/fields/ApplicationStatus";
import {getApplicationColor} from "../../app/utils/getApplicationColors";
import {Messages} from "../../app/constants/notification_messages";
import {getExperienceDisplayName, getLawyerTypeDisplayName} from "../../firebase/fields/OfferLawyerType";
import {removeHttpsFromUrl} from "../../app/utils/remove_https";

const {Paragraph, Text} = Typography;


interface IProps {
    application: IApplicationListApplication;
    offerId: string;
    enableAcceptButton?: boolean;
    fetchPrivateOfferDetail?: () => void;
    offerPrice: number | null;
    onSuccessfulAccept?: () => void;
    showContactData?: boolean;
}
export const ApplicationItem = (props: IProps) => {
    const {application} = props;
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (application.status === ApplicationStatus.ACCEPTED) {
            props.fetchPrivateOfferDetail && props.fetchPrivateOfferDetail();
        }
    }, [application]);

    const renderBadge = () => {
        const date = moment(application.createTime.toDate());
        const formatteDate = date.format("DD.MM.YYYY");
        const statusObj = getApplicationStatusDisplayName(application.status);
        return <><p className={"main-color"} style={{fontSize: "14px"}}>Data zgłoszenia: {formatteDate}</p><Badge status={statusObj.name} text={statusObj.label}/></>
    };

    const renderCardMeta = () => {
        return (
            <Card.Meta
                avatar={<Avatar src={application.userPhotoURL ? application.userPhotoURL : ""}/>}
                title={<span>{application.userDisplayName}{renderSocialMedia()}</span>}
                description={<span>{application.userLawyerType && getLawyerTypeDisplayName(application.userLawyerType)} {application.userExperience && `z ${getExperienceDisplayName(application.userExperience)} stażem`} </span>}
            />
        )
    };

    const handleAccept = async () => {
        setSubmitting(true);
        const data = await acceptApplication(props.application.id, props.offerId);
        if (data) {
            notification.success(Messages.application.accept);
            // refetch applications
            props.onSuccessfulAccept && props.onSuccessfulAccept();
        } else {
            notification.error(Messages.application.acceptError);
        }

        setSubmitting(false);
    };


    const renderSocialMedia = () => {
        return (
            <span style={{paddingLeft: 15}}>
                 {props.application.userFacebookProfileUrl &&
                 <a href={`//${removeHttpsFromUrl(props.application.userFacebookProfileUrl)}`} target={"_blank"}><Icon type={"facebook"} style={{fontSize: 24, color: "#626d8e", cursor: "pointer"}}/></a>}
                 {props.application.userLinkedInProfileUrl &&
                 <a href={`//${removeHttpsFromUrl(props.application.userLinkedInProfileUrl)}`} target={"_blank"}><Icon type={"linkedin"} style={{fontSize: 24, color: "#626d8e", cursor: "pointer"}}/></a>}
            </span>
        )
    }

    const {backgroundColor} = getApplicationColor(application.status);
    return (
        <List.Item style={{height: "auto", width: "100%", overflow: "hidden"}}>
            <Card title={renderCardMeta()}
                  style={{height: "auto", width: "100%", backgroundColor}}
                  extra={<>
                      <Col xs={{span: 0}} sm={{span: 24}}>{renderBadge()}</Col>
                  </>}
                  hoverable
                  bodyStyle={{paddingTop: 0, paddingBottom: 0}}
            >
                <Row>
                    <Col xs={{span: 22, offset: 1}}
                         sm={{span: 11, offset: 1}}>
                        {props.showContactData && (
                            <>
                                <Paragraph> <a className="contact-detail" href={`mailto:${application.contactMail}`} style={{fontSize: 18, color: "#626d8e"}}>
                                    <Icon type="mail"/> {application.contactMail}
                                </a></Paragraph>
                                <Paragraph> <a className="contact-detail" href={`tel:+${application.contactPhone}`} style={{fontSize: 18, color: "#626d8e"}}>
                                    <Icon type="phone"/> {application.contactPhone}
                                </a></Paragraph>
                                <Paragraph><Icon type={"read"}/> {application.userNumber ? application.userNumber : "Brak wpisu"}</Paragraph>
                            </>
                        )}
                    </Col>

                    <Col xs={{span: 22, offset: 1}}
                         sm={{span: 10, offset: 1}}>
                        <Paragraph><Text>Faktura VAT:</Text> {application.userInvoice ?
                            <Icon type={"like"} style={{fontSize: 24}}/> :
                            <Icon type={"dislike"} style={{fontSize: 24}}/>}</Paragraph>
                        <Paragraph><Text>Stawka: {props.offerPrice ? props.offerPrice : application.price} zł</Text></Paragraph>
                    </Col>
                </Row>
                <Paragraph>
                    {application.description && <Text>Wiadomość: {application.description}</Text>}
                </Paragraph>

                {!!props.enableAcceptButton && (
                     <Button type="primary" onClick={handleAccept} disabled={isSubmitting}>
                    <Spin spinning={isSubmitting}>
                        Zaakceptuj zgłoszenie
                    </Spin>
                </Button>
                )}
            </Card>
        </List.Item>
    )
};

// forms[0] for quantity 1 - przykład
// forms[1] for quantity 2 - przykłady
// forms[2] for quantity 5 - przykładów
export const pluralize = (forms: [string, string, string]) => (n: number): string => forms[getFormIndex(n)];

function getFormIndex(n: number): 0 | 1 | 2 {
    if (n === 1) {
        return 0; // quantity 1
    }

    if (10 <= n % 100 && n % 100 < 20) { // teen-odd = 1x
        return 2; // quantity 5
    }

    if (n % 10 < 2) { // x0, x1
        return 2; // quantity 5
    }

    if (n % 10 > 4) { // x5, x6, x7, x8, x9
        return 2; // quantity 5
    }

    return 1; // quantity 2
}

import React from "react";
import {Typography} from "antd";

const {Title} = Typography;

export const PrivacyPolicy = () => {
    return (
            <ol className={"main-color"}>
                <li>
                    <b>Kto jest administratorem Twoich danych osobowych?</b>
                    <div>Administratorami danych osobowych są Tomasz Ciecierski i Michał Skoczylas.</div>
                </li>
                <li>
                    <b>Jak skontaktować się z administratorami danych osobowych?</b>
                    <div>Możesz skontaktować się poprzez email na Adres Kontaktowy.
                    </div>
                </li>
                <li>
                    <b>W jakim celu oraz w oparciu o jaką podstawę prawną przetwarzamy Twoje dane
                        osobowe? Jak długo przechowujemy Twoje dane?</b>
                    <div>Celem przetwarzania danych jest rejestracja Użytkownika i umożliwienie wykonywania wszelkich
                        zadań
                        związanych z korzystaniem z Serwisu (art. 6 ust. 1 lit b) RODO (wykonanie
                        umowy).
                        Dane będą przechowywane przez 5 lat od ostatniej aktywności, lub do momentu zgłoszenia chęci
                        usunięcia konta przez Użytkownika na Adres Kontaktowy.
                    </div>
                </li>
                <li>
                    <b>Komu przekazujemy Twoje dane osobowe?</b>
                    <div>Dane Użytkownika - wyświetlamy tylko innym użytkownikom w Ofercie którą dodasz do Serwisu.
                    </div>
                    <div> Dane Kontaktowe:
                        <br/> jeśli dodajesz Ofertę, to Twoje dane zostaną przekazane tylko wybranemu Substytutowi.
                        <br/> jeśli wysyłasz zgłoszenie, Twoje dane są przekazane Autorowi Oferty.
                    </div>
                </li>
                <li>
                    <b>Jakie przysługują Użytkownikowi prawa w związku z przetwarzaniem Twoich danych osobowych?</b>
                    <div>Użytkownikowi przysługuje:</div>
                    <ul>
                        <li>Prawo do usunięcia danych,</li>
                        <li>Prawo do ograniczenia przetwarzania,</li>
                        <li>Prawo dostępu do danych,</li>
                        <li>Prawo do sprostowania danych,</li>
                        <li>Prawo do przenoszenia danych,</li>
                        <li>Prawo do zgłaszania skarg.</li>
                    </ul>
                </li>
                <li>
                    <b>Czy podanie przez Użytkownika danych osobowych jest obowiązkowe?</b>
                    <div>Podanie przez Użytkownika danych osobowych jest dobrowolne, lecz niezbędne do korzystania z
                        usług oferowanych w Serwisie. Konsekwencją niepodania danych jest brak dostępu do usług.
                    </div>
                </li>

            </ol>
    )
}

import {InputNumber} from "antd";
import React from "react";
import {FieldProps} from "formik";

interface IProps extends FieldProps<any> {
    placeholder: string;
    onChange: (value: number | null) => void;
    style?: any;
    acceptsNull?: boolean;
}
export const InputNumberField = (props: IProps) => {
    const {placeholder, acceptsNull} = props;
    const handleChange = (value?: number) => {
        if (acceptsNull) {
            value ? props.onChange(value) : props.onChange(null);
        }
        else {
            value && props.onChange(value);
        }
    };

    return (
        <InputNumber style={props.style} type="number" pattern={"\\d*"} min={0} {...props.field} placeholder={placeholder} onChange={handleChange}/>
    )
}

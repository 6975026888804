import * as pathToRegexp from "path-to-regexp";
//
import {appPath} from "./app_path";


interface IOfferParams {
    offerId: string;
}

/**
 * AppLink
 */
export const appLink = {
    homepage: () => appPath.homepage,
    offer: {
        list: () => appPath.offer.list,
        add: () => appPath.offer.add,
        detail: {
            base: parseParams<IOfferParams>(appPath.offer.detail.base),
        }
    },
    application: {
        detail: {
            base: parseParams<{ offerId: string, applicationId: string }>(appPath.offer.detail.application.detail.base)
        }
    }
};

/**
 * Helper
 */
function parseParams<T extends object>(path: string) {
    const compiler = pathToRegexp.compile(path);
    return (params: T) => compiler(params);
}

import React from "react";
import {Layout, Row, Typography} from "antd";
import {PrivacyPolicy} from "../components/PrivacyPolicy";

const {Content} = Layout;
const {Title} = Typography;

export const PrivacyView = () => {
    return (
        <Layout style={{padding: "4%"}}>
            <Content>
                <Row>
                    <Title level={2} style={{textAlign: "center"}}>Polityka prywatności</Title>

                    <PrivacyPolicy/>
                </Row>
            </Content>
        </Layout>
    );
};

export const Messages = {
    offer: {
        add: {
            message: 'Udało się!',
            description:
                'Oferta dodana.',
        },
        edit: {
            message: 'Udało się!',
            description:
                'Oferta edytowana.',
        }
    },
    preference: {
        add: {
            message: 'Udało się!',
            description:
                'Dodano nową listę.'
        },
        delete: {
            message: 'Udało się!',
            description: 'Lista usunięta.'
        },
        confirm: "Czy na pewno usunąć tę listę?"
    },
    application: {
         add: {
            message: 'Udało się!',
            description:
                'Zgłoszenie wysłane.'
         },
        accept: {
            message: 'Udało się!',
            description: 'Zgłoszenie zaakceptowane.',
        },
        acceptError: {
            message: 'Błąd!',
            description: "Akceptacja zgłoszenia nie powiodła się."
        }
    },
    user: {
        edit: {
             message: 'Udało się!',
            description:
                'Dane zmienione.'
        }
    }
};


import {Checkbox} from "antd";
import React from "react";
import {FieldProps} from "formik";

interface IProps extends FieldProps<any> {
}

export const CheckboxField = (props: IProps) => {
    return (
        <Checkbox {...props.field} checked={props.field.value}/>
    )
};

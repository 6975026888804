import React from "react"
import {Timeline} from "antd";
import {filter, isEmpty} from "lodash";
import {OfferLawyerType} from "../../firebase/fields/OfferLawyerType";
import {pluralize} from "../../app/utils/pluralize";

interface IProps {
    contactMail: string;
    contactPhone: string;
    lawyerType: OfferLawyerType | null;
    number: string;
    invoice: boolean;
    experience: number | null;
    facebookProfileUrl: string;
    linkedInProfileUrl: string;
}

export const UserMissingFields = (props: IProps) => {
    const missingCount = filter(props, (val) => !val);
    const pluralMissingFields = pluralize(["pola", "pól", "pól"]);
    const hasMissing = missingCount.length > 0;
    return (
            <Timeline mode="right" style={{fontSize: 14}}>
                {!props.contactPhone && <Timeline.Item color={"red"}>Numer telefonu </Timeline.Item>}
                {!props.contactMail && <Timeline.Item color={"red"}>Adres e-mail</Timeline.Item>}
                {!props.lawyerType && <Timeline.Item color={"red"}>Profesja</Timeline.Item>}
                {!props.number && <Timeline.Item color={"red"}>Nr wpisu</Timeline.Item>}
                {!props.invoice && <Timeline.Item>Faktura</Timeline.Item>}
                {!props.experience && <Timeline.Item color={"red"}>Lata doświadczenia</Timeline.Item>}
                {!props.facebookProfileUrl && <Timeline.Item color={"red"}>Link do profilu na Facebooku</Timeline.Item>}
                {!props.linkedInProfileUrl && <Timeline.Item color={"red"}>Link do profilu na LinkedIn</Timeline.Item>}
                <Timeline.Item
                    style={{color: hasMissing ? "red" : "green"}}
                    color={"green"}

                >
                    {hasMissing ?
                        `Brakuje Ci ${missingCount.length} ${pluralMissingFields(missingCount.length)}, aby aplikować na więcej ofert ;(` :
                        `Brawo! Twój profil jest w pełni uzupełniony. Możesz aplikować na więcej ofert ;)`
                    }
                </Timeline.Item>
            </Timeline>
    )
}

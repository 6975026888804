import {map} from "lodash";
import firebase from "firebase/app";
import moment from "moment";
import {collectionPath} from "../../firebase/collection_path";
import {OfferType} from "../../firebase/fields/OfferType";
import {firestoreDb} from "../../firebase/initialize_firebase_app";
import {OfferStatus} from "../../firebase/fields/OfferStatus";
import {IOffer, IOfferPreview} from "../../firebase/documents/IOffer";
import {OfferRegion} from "../../firebase/fields/OfferRegion";
type QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
type QuerySnapshot = firebase.firestore.QuerySnapshot;


export interface IOfferListOffer extends IOffer {
    id: string;
}
export interface IOfferPreviewWithId extends IOfferPreview {
    id: string;
}

export const fetchFilteredOfferList = (region: OfferRegion, type: OfferType): Promise<IOfferListOffer[]> => {
    return firestoreDb
        .collection(collectionPath.offers)
        .where("region", "==", region)
        .where("type", "==", type)
        .where("status", "==", OfferStatus.PUBLISHED)
        .orderBy("createTime", "desc") // we want fresh offers to go first
        .get()
        .then((querySnapshot: QuerySnapshot): IOfferListOffer[] => {
            return map<QueryDocumentSnapshot, IOfferListOffer>(querySnapshot.docs, (doc: QueryDocumentSnapshot): IOfferListOffer => {
                return {
                    ...doc.data() as IOffer,
                    id: doc.id as string
                };
            });
        })
        .catch(e => {
            console.error("fetchFilteredOfferList", region, type, e);
            throw e;
        });
};

export const fetchOwnOffers = (userId: string) => {
    return firestoreDb
        .collection(collectionPath.offers)
        .where("user", "==", userId)
        .where("date", ">", moment().subtract(7, "days").toDate()) // list offers up to 7 days after their due date
        .orderBy("date", "asc") // we want closed offers to go first and then the closest in time to happen, we may even encourage (here) to mark the closed offers
        .get()
        .then((querySnapshot: QuerySnapshot): IOfferListOffer[] => {
            return map<QueryDocumentSnapshot, IOfferListOffer>(querySnapshot.docs, (doc: QueryDocumentSnapshot): IOfferListOffer => {
                return {
                    ...doc.data() as IOffer,
                    id: doc.id as string
                };
            });
        })
        .catch(e => {
            console.error("fetchOwnOffers", userId, e);
            throw e;
        });
};


export const fetchOffersPreviewList = (): Promise<IOfferPreviewWithId[]> => {
    return firestoreDb
        .collection(collectionPath.offersPreview)
        .orderBy("createTime", "desc")
        .limit(4)
        .get()
        .then((querySnapshot: QuerySnapshot): IOfferPreviewWithId[] => {
            return map<QueryDocumentSnapshot, IOfferPreviewWithId>(querySnapshot.docs, (doc: QueryDocumentSnapshot): IOfferPreviewWithId => {
                return {
                    ...doc.data() as IOfferPreview,
                    id: doc.id
                };
            });
        })
        .catch(e => {
            console.error("fetchOffersPreviewList", e);
            throw e;
        });
};


import {Input} from "antd";
import React from "react";
import {FieldProps} from "formik";

interface IProps extends FieldProps<any> {
    placeholder: string;
    onChange: () => void;
    suffix?: JSX.Element
}
export const InputField = (props: IProps) => {
    const {placeholder} = props;

    return (
        <Input type="text" {...props.field} placeholder={placeholder} suffix={props.suffix}/>
    )
};

import {collectionPath} from "../../firebase/collection_path";
import {firestoreDb} from "../../firebase/initialize_firebase_app";
import {IOffer, IOfferCreate} from "../../firebase/documents/IOffer";
import {waitForData} from "../../firebase/actions/wait_for_data";
import {IOfferDetailOffer} from "./fetch_offer_detail";
import {OfferStatus} from "../../firebase/fields/OfferStatus";


export const createOffer = (offer: IOfferCreate): Promise<IOfferDetailOffer | void> => {
    return firestoreDb
        .collection(collectionPath.offers)
        .add(offer)
        .then(async docRef => {
            const data = await waitForData("createOffer")<IOffer>(docRef, data => data.status === OfferStatus.PUBLISHED);
            return {...data, id: docRef.id} as IOfferDetailOffer;
        })
        .catch(e => {
            console.error("createOffer", offer, e);
            throw e;
        });
};

import React, {useMemo} from "react";
import {Link, matchPath, RouteComponentProps, withRouter} from "react-router-dom";
import firebase from "firebase/app";
import {Affix, Anchor, Avatar, Button, Icon, Menu, Tooltip, Typography} from "antd";
import {appLink} from "../utils/app_link";
import AnchorLink from "antd/lib/anchor/AnchorLink";
import {appPath} from "../utils/app_path";
import logo from "../../images/logo.png"
import {IUser} from "../../firebase/documents/IUser";
const {Text} =  Typography;


interface IProps extends RouteComponentProps {
    userProfile: IUser | null;
    logout: () => void;
}

const HeaderC = (props: IProps) => {
    const isDashboard = matchPath(props.history.location.pathname, {
        exact: true,
        strict: true,
        path: appPath.offer.list
    });

    const isOfferAdd = matchPath(props.history.location.pathname, {
        exact: true,
        strict: true,
        path: appPath.offer.add
    });
    const isHomePage = matchPath(props.history.location.pathname, {
        exact: true,
        strict: true,
        path: appPath.homepage
    });

    const getSelectedKey = useMemo(() => {
        let keys = [];
        isHomePage && keys.push("homepage");
        isDashboard && keys.push("offers");
        isOfferAdd && keys.push("add-offer");
        return keys;
    }, [props.history.location]);

    return (
        <Affix offsetTop={0}>
            <Anchor>
                {props.userProfile ? (
                    <Menu theme="dark" mode="horizontal" style={{backgroundColor: "#efeff6", height: 50, overflow: "hidden"}} selectedKeys={getSelectedKey}>
                        <Menu.Item key={"homepage"}>
                            <Link to="/">
                                <img src={logo} alt={'SubstytucjeProcesowe'} height={25}/><span className={"dn-below-768"}>SubstytucjeProcesowe</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={"offers"}>
                            <Link to="/oferty/">
                                Mój Pulpit
                            </Link>
                        </Menu.Item>
                        <Menu.Item key={"add-offer"} >
                            <Link to={appLink.offer.add()}>
                              Dodaj ofertę
                            </Link>
                        </Menu.Item>

                    {/* only for dashboard view*/}

                    {isDashboard && <Menu.Item className={"a-l-menu-item no-marg-left"} style={{marginLeft: "4%"}} key={"own-applications"}><AnchorLink href={'#moje-zgloszenia'} title={<Button className={"a-l-button"} type={"link"}>#Moje zgłoszenia</Button>}/></Menu.Item>}
                    {isDashboard && <Menu.Item className={"a-l-menu-item"} key={"own-lists"}><AnchorLink href={'#moje-listy'} title={<Button className={"a-l-button"}  type={"link"}>#Moje Listy</Button>}/></Menu.Item>}
                    {isDashboard && <Menu.Item className={"a-l-menu-item"} key={"own-offers"}><AnchorLink href={'#moje-oferty'} title={<Button className={"a-l-button"}  type={"link"}>#Moje oferty</Button>}/></Menu.Item>}
                    <Menu.Item style={{float: "right"}} onClick={props.logout}>
                        {props.userProfile && (
                            <>
                                <Icon type="logout"/>Wyloguj
                            </>
                        )}
                    </Menu.Item>
                    <Menu.Item style={{float: "right"}}>
                        {props.userProfile && (
                            <span style={{cursor: "pointer"}} onClick={() => props.history.push(appPath.account)}>
                                <Avatar style={{margin: "0 3%"}} src={props.userProfile.photoURL ? props.userProfile.photoURL : ""}/>
                                <Text style={{fontWeight: 800}}>{props.userProfile.displayName}</Text>
                            </span>
                        )}
                    </Menu.Item>
                    <Menu.Item style={{float: "right"}}>
                        <Tooltip placement="bottomLeft" title="Najczęściej zadawane pytania">
                            <Link to="/faq/">
                                <Icon type={"question-circle"}/>Pomoc
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                </Menu>
                // Guest
                ) : (
                    <Menu theme="dark" mode="horizontal" style={{backgroundColor: "#efeff6", borderBottom: "0px"}}>
                        <Menu.Item>
                            <Link to="/" >
                                <img src={logo} alt={'SubstytucjeProcesowe'} height={25}/><span className={"dn-below-768"}>SubstytucjeProcesowe</span>
                            </Link>
                        </Menu.Item>
                            <Menu.Item style={{float: "right"}}>
                                <Link to="/zaloguj/">
                                    Zaloguj się
                                </Link>
                            </Menu.Item>
                          <Menu.Item style={{float: "right"}}>
                              <Tooltip placement="bottomLeft" title="Najczęściej zadawane pytania">
                                  <Link to="/faq/">
                                      <Icon type={"question-circle"}/>Pomoc
                                  </Link>
                              </Tooltip>

                        </Menu.Item>

                    </Menu>
                )}
            </Anchor>
        </Affix>
    );
};

export const Header = withRouter(HeaderC);

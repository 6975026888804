import React from "react";
import {Card, Col, Typography} from "antd";


interface IProps {
    question: string;
    description: JSX.Element | string;
}

const {Title} = Typography;

export const FAQItem = (props: IProps) => {
    return (
        <Col xs={{span: 24}} >
            <Card hoverable style={{border: "none", margin: "1%"}} bodyStyle={{display: "flex", flexDirection: "column", alignItems: "center"}} >
                <Title level={4}>{props.question}</Title>
                <br/>
                <div className={"benefit-description"} style={{textAlign: "center"}}>{props.description}</div>
            </Card>
        </Col>
    )
}

import React, {useCallback} from "react";
import {Formik, FormikActions, FormikErrors, FormikProps} from "formik";
import {AddApplicationForm} from "./AddApplicationForm";
import {Row} from "antd";

export interface IApplicationForm {
    price: number | null;
    description: string;
    phone: string;
    email: string;
}

interface IProps {
    onSubmit: (values: IApplicationForm, setSubmitting: (isSubmitting: boolean) => void) => void;
    offerPrice: number | null;
    userPhone: string | null;
    userEmail: string | null;
}

export const AddApplication = (props: IProps) => {
    const submitValidation = useCallback((values: IApplicationForm): FormikErrors<IApplicationForm> => {
        const errors: FormikErrors<IApplicationForm> = {};
        if (values.price == null) {
            errors.price = "Pole wymagane";
        }
        if (!values.phone && !values.email) {
            errors.phone = "Uzupełnij chociaż jedno z pól kontaktowych";
            errors.email = 'Uzupełnij chociaż jedno z pól kontaktowych';
        }
        return errors;
    }, []);


    return (
        <Row type={"flex"} justify={"center"}>
            <Formik
                initialValues={{price: props.offerPrice || null, description: "", phone: props.userPhone || "", email: props.userEmail || ""}}
                validate={submitValidation}
                onSubmit={(values: IApplicationForm, actions: FormikActions<IApplicationForm>) => {
                    actions.setSubmitting(true);
                    props.onSubmit(values, actions.setSubmitting);
                }}
                render={(renderProps: FormikProps<IApplicationForm>) => <AddApplicationForm {...renderProps} offerPrice={props.offerPrice} />}
            />
        </Row>)
};


export enum OfferRegion {
    dolnoslaskie = "dolnoslaskie",
    kujawsko_pomorskie = "kujawsko_pomorskie",
    lodzkie = "lodzkie",
    lubelskie = "lubelskie",
    lubuskie = "lubuskie",
    malopolskie = "malopolskie",
    mazowieckie = "mazowieckie",
    opolskie = "opolskie",
    podkarpackie = "podkarpackie",
    podlaskie = "podlaskie",
    pomorskie = "pomorskie",
    slaskie = "slaskie",
    swietokrzyskie = "swietokrzyskie",
    warminsko_mazurskie = "warminsko_mazurskie",
    wielkopolskie = "wielkopolskie",
    zachodniopomorskie = "zachodniopomorskie"
}

export const offerRegionOptions = [
    {value: OfferRegion.dolnoslaskie, label: "dolnośląskie"},
    {value: OfferRegion.kujawsko_pomorskie, label: "kujawsko-pomorskie"},
    {value: OfferRegion.lodzkie, label: "łódzkie"},
    {value: OfferRegion.lubelskie, label: "lubelskie"},
    {value: OfferRegion.lubuskie, label: "lubuskie"},
    {value: OfferRegion.malopolskie, label: "małopolskie"},
    {value: OfferRegion.mazowieckie, label: "mazowieckie"},
    {value: OfferRegion.opolskie, label: "opolskie"},
    {value: OfferRegion.podkarpackie, label: "podkarpackie"},
    {value: OfferRegion.podlaskie, label: "podlaskie"},
    {value: OfferRegion.pomorskie, label: "pomorskie"},
    {value: OfferRegion.slaskie, label: "śląskie"},
    {value: OfferRegion.swietokrzyskie, label: "świętokrzyskie"},
    {value: OfferRegion.warminsko_mazurskie, label: "warmińsko-mazurskie"},
    {value: OfferRegion.wielkopolskie, label: "wielkopolskie"},
    {value: OfferRegion.zachodniopomorskie, label: "zachodniopomorskie"}
];

export const offerRegionMapWhere = {
    [OfferRegion.dolnoslaskie]: "w województwie dolnośląskim",
    [OfferRegion.kujawsko_pomorskie]: "w województwie kujawsko-pomorskim",
    [OfferRegion.lodzkie]: "w województwie łódzkim",
    [OfferRegion.lubelskie]: "w województwie lubelskim",
    [OfferRegion.lubuskie]: "w województwie lubuskim",
    [OfferRegion.malopolskie]: "w województwie małopolskim",
    [OfferRegion.mazowieckie]: "w województwie mazowieckim",
    [OfferRegion.opolskie]: "w województwie opolskim",
    [OfferRegion.podkarpackie]: "w województwie podkarpackim",
    [OfferRegion.podlaskie]: "w województwie podlaskim",
    [OfferRegion.pomorskie]: "w województwie pomorskim",
    [OfferRegion.slaskie]: "w województwie śląskim",
    [OfferRegion.swietokrzyskie]: "w województwie świętokrzyskim",
    [OfferRegion.warminsko_mazurskie]: "w województwie warmińsko-mazurskim",
    [OfferRegion.wielkopolskie]: "w województwie wielkopolskim",
    [OfferRegion.zachodniopomorskie]: "w województwie zachodniopomorskim"
};

export const isOfferRegionValid = (region: string): boolean => !!offerRegionMapWhere[region as OfferRegion];

export const fromPlacesVoivodeshipToOfferRegion = (voivodeship: string): OfferRegion => {
    // different cases and ways of naming are provided intentionally - GOOGLE MAPS is crazy
    switch (voivodeship) {
        case "Województwo dolnośląskie":
        case "Dolnośląskie":
            return OfferRegion.dolnoslaskie;
        case "kujawsko-pomorskie":
        case "Kujawsko-Pomorskie":
            return OfferRegion.kujawsko_pomorskie;
        case "województwo łódzkie":
        case "Łódzkie":
            return OfferRegion.lodzkie;
        case "lubelskie":
        case "Lubelskie":
            return OfferRegion.lubelskie;
        case "lubuskie":
        case "Lubuskie":
            return OfferRegion.lubuskie;
        case "małopolskie":
        case "Małopolskie":
            return OfferRegion.malopolskie;
        case "mazowieckie":
        case "Mazowieckie":
            return OfferRegion.mazowieckie;
        case "opolskie":
        case "Opolskie":
            return OfferRegion.opolskie;
        case "Województwo podkarpackie":
        case "Podkarpackie":
            return OfferRegion.podkarpackie;
        case "podlaskie":
        case "Podlaskie":
            return OfferRegion.podlaskie;
        case "pomorskie":
        case "Pomorskie":
            return OfferRegion.pomorskie;
        case "śląskie":
        case "Śląskie":
            return OfferRegion.slaskie;
        case "świętokrzyskie":
        case "Świętokrzyskie":
            return OfferRegion.swietokrzyskie;
        case "warmińsko-mazurskie":
        case "Warmińsko-Mazurskie":
            return OfferRegion.warminsko_mazurskie;
        case "wielkopolskie":
        case "Wielkopolskie":
            return OfferRegion.wielkopolskie;
        case "Województwo zachodniopomorskie":
        case "Zachodniopomorskie":
            return OfferRegion.zachodniopomorskie;
        default:
            throw new Error(`Invalid places voivodeship: ${voivodeship}`);
    }
};

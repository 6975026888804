import * as React from "react";
import {useContext} from "react";
import {OfferDetail} from "../components/OfferDetail";
import {RouteComponentProps} from "react-router";
import {LoginView} from "../../auth/views/LoginView";
import {Spin} from "antd";
import {CenterBox} from "../../app/components/CenterBox";
import {UserProfileContext} from "../../app/Layout";


interface IProps extends RouteComponentProps<{offerId: string}> {
    isAuthPending: boolean;
    isAnonymous: boolean;
}

export const OfferDetailView = (props: IProps) => {
    const {userProfile} = useContext(UserProfileContext);

    if (props.isAuthPending) {
        return <CenterBox><Spin style={{fontSize: 400}} size={"large"} spinning={true}/></CenterBox>
    }

    if (userProfile == null) {
        const successUrl = props.match.params.offerId && `/oferty/${props.match.params.offerId}/`;

        return <LoginView userData={userProfile} isAnonymous={props.isAnonymous} successUrl={successUrl} offerId={props.match.params.offerId}
                          title={"Zaloguj się, żeby zobaczyć ofertę"}/>
    }

    if (userProfile) {
        return (
            <OfferDetail userEmail={userProfile.contactMail} userPhone={userProfile.contactPhone}
                         userId={userProfile.uid}
                         offerId={props.match.params.offerId} hash={props.location.hash}/>
        );
    }
    return null;

};


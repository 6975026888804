import React, {useCallback} from 'react';
import {Col, notification, Row, Typography} from "antd";
import {Formik, FormikActions, FormikErrors, FormikProps} from 'formik';
import moment from "moment";
import {OfferDetailForm} from "./OfferDetailForm";
import {IOfferForm} from "../models/IOfferForm";
import {createOffer} from "../actions/create_offer";
import {IOfferCreate, IOfferFormJson} from "../../firebase/documents/IOffer";
import {toJsonValues} from "../form.utils";
import {OfferStatus} from "../../firebase/fields/OfferStatus";
import {containerGrid} from "../style.utils";
import {Messages} from "../../app/constants/notification_messages";
import {OfferType} from "../../firebase/fields/OfferType";


interface IProps {
    userId: string;
    onSuccessfulAdd: () => void;
    userEmail: string | null;
    userPhone: string | null;
}

const {Title} = Typography;

export const OfferAdd = (props: IProps) => {


    const initialValues: IOfferForm = {
        city: null,
        type: OfferType.SUBSTITUTION,
        addressName: "",
        addressDepartment: "",
        addressStreet: "",
        date: null,
        time: null,
        lawyerTypes: [],
        invoiceRequired: false,
        experienceRequired: null,
        numberRequired: false,
        facebookRequired: false,
        linkedInRequired: false,
        price: null,
        description: "",
        contactMail: props.userEmail || "",
        contactPhone: !!props.userPhone ? parseInt(props.userPhone, 10) : null,
        descriptionPrivate: ""
    };


    const submitValidation = useCallback((values: IOfferForm): FormikErrors<IOfferForm> => {
        const errors: FormikErrors<IOfferForm> = {};
        if (values.city == null) {
            errors.city = "Pole wymagane";
        }
        if (values.type == null) {
            errors.type = "Pole wymagane";
        }
        if (!values.addressName) {
            errors.addressName = "Pole wymagane";
        }
        // date
        if (values.date == null) {
            errors.date = 'Pole wymagane';
        }
        else if (values.date.isBefore(moment(), "days")) {
            errors.date = "Termin musi być datą dzisiejszą lub przyszłą";
        }
        else if (values.date.isAfter(moment().add(30, "days"), "days")) {
            errors.date = "Termin nie może przekraczać 30 dni";
        }
        // time
        if (values.time != null && values.date != null) {
            if (values.date.isSame(moment(), "days")) {
                if (values.time.isBefore(moment().subtract(5, "minutes"), "minutes")) {
                    errors.time = "Godzina i data muszą odnosić się do przyszłości";
                }
            }
        }

        return errors;
    }, []);

    return (
        <Row style={{padding: "2% 0", height: "100%"}}>
            <Col {...containerGrid}>
                <Title level={3}>Dodaj Ofertę</Title>
                <Formik
                initialValues={initialValues}
                validate={submitValidation}
                onSubmit={async (values: IOfferForm, actions: FormikActions<IOfferForm>) => {
                    actions.setSubmitting(true);
                    const offerFormJson: IOfferFormJson | null = toJsonValues(values, field => actions.setFieldError(field, "Błąd"));
                    if (offerFormJson == null) {
                        return actions.setSubmitting(false);
                    }
                    const offerCreate: IOfferCreate = {...offerFormJson, user: props.userId, status: OfferStatus.CREATING};
                    const data = await createOffer(offerCreate);
                    if (data) {
                        notification.success(Messages.offer.add);
                        props.onSuccessfulAdd();
                    }
                    else {
                        notification.error({message: "Błąd!", description: "Zapis oferty nie powiódł się."});
                    }
                    actions.setSubmitting(false);
                }}
                render={(renderProps: FormikProps<IOfferForm>) => {
                    return (
                        <OfferDetailForm buttonText="Dodaj ofertę" {...renderProps} />
                    )
                }}
            />
            </Col>
        </Row>
    );
};

export enum OfferStatus {
    // meta statuses
    CLOSING = "closing",        // frontend state for close
    CREATING = "creating",      // frontend state for creation
    UPDATING = "updating",      // frontend state for edit
    // actual statuses
    CLOSED = "closed",          // closed by author (after application success or cancellation)
    PUBLISHED = "published"     // visible on lists & eligible to apply
}

export const getOfferDetailStatusDisplayName = (status: OfferStatus) : {name: string; label: string; color: string} => {
    switch(status) {
        case OfferStatus.PUBLISHED:
            return {
                name: "folder-open",
                label: "Oferta oczekuje na zgłoszenia",
                color: "#1890ff"
            };
        case OfferStatus.CLOSED:
            return {
                name: "close-circle",
                label: "Oferta zamknięta",
                color: "red"
            };
        default:
            throw new Error(`getOfferDetailStatusDisplayName: invalid offer status ${status}`);
    }
};

import {map} from "lodash";
import moment from "moment";
import {firestoreDb} from "../../firebase/initialize_firebase_app";
import {collectionGroupPath, collectionPath} from "../../firebase/collection_path";
import {IApplication} from "../../firebase/documents/IApplication";


export interface IApplicationListApplication extends IApplication {
    id: string;
}

export const fetchApplications = (offerId: string, userId?: string) => {
    const colRef = firestoreDb.collection(collectionPath.offersApplications({offerId}));
    const finalRef = userId != null ? colRef.where("user", "==", userId) : colRef;
    return finalRef
        .orderBy("createTime", "desc") // we want fresh applications to go first
        .get()
        .then(querySnapshot => {
            return map(querySnapshot.docs, (doc): IApplicationListApplication => {
                return {
                    ...doc.data() as IApplication,
                    id: doc.id as string
                };
            });
        })
        .catch(e => {
            console.error("fetchApplications", offerId, userId, e);
            throw e;
        });
};

export const fetchAllUserApplications = (userId: string): Promise<IApplicationListApplication[]> => {
    return firestoreDb
        .collectionGroup(collectionGroupPath.applications)
        .where("user", "==", userId)
        .where("offerDate", ">", moment().subtract(7, "days").toDate()) // list applications up to 7 days after their offer's due date
        .orderBy("offerDate", "asc") // we expect old applications to go first and then from the closest in time in future
        .get()
        .then(querySnapshot => {
            return map(querySnapshot.docs, doc => {
                return {...doc.data() as IApplication, id: doc.id};
            });
        })
        .catch(e => {
            console.error("fetchAllUserApplications", userId, e);
            throw e;
        });
};

import React from "react";

import {IUserPreference} from "../../firebase/fields/IUserPreference";
import {Button, Col, Form, Row, Spin} from "antd";
import {FormItemWithValidation} from "../../offer/components/FormItemWithValidation";
import {Field, FormikProps} from "formik";
import {SelectField} from "../../common/form-fields/SelectField";
import {OfferTypeOptions} from "../../offer/components/OfferFormOptions";
import {AutocompleteField} from "../../common/form-fields/AutocompleteField";
import {colSizesTwoFirst, colSizesTwoSecond} from "../../offer/style.utils";
import {OfferRegion, offerRegionOptions} from "../../firebase/fields/OfferRegion";
import {OfferType} from "../../firebase/fields/OfferType";


export interface IUserPreferenceForm {
    region: OfferRegion | "",
    type: OfferType | null
}

interface IOwnProps {

}
export const AddPreferenceForm = (props: IOwnProps & FormikProps<IUserPreferenceForm>) => {
    const getFieldProps = (name: keyof IUserPreference) => ({
        name,
        onChange: (value: string) => handleOnChange(value, name)
    });

    const getFieldWrapperProps = (name: keyof IUserPreference) => ({
        error: props.errors[name],
        touched: props.touched[name]
    });

    const handleOnChange = (value: any, name: string) => {
        props.setFieldValue(name, value);
    };
    return (
        <Form>
            <Row type={"flex"} justify={"center"} align={"middle"}>
                <Col {...colSizesTwoFirst}>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("region")}
                        label="Region"
                        required
                    >
                        <Field
                            name={"region"}
                            onChange={(value: string) => handleOnChange(value, "region")}
                            component={AutocompleteField}
                            placeholder={"Wybierz region"}
                            data={offerRegionOptions}
                        />
                    </FormItemWithValidation>
                </Col>
                <Col {...colSizesTwoSecond}>

                    <FormItemWithValidation
                        {...getFieldWrapperProps("type")}
                        label="Rodzaj usługi"
                        required
                    >
                        <Field
                            {...getFieldProps("type")}
                            component={SelectField}
                            options={OfferTypeOptions}
                            placeholder="Rodzaj usługi"
                        />
                    </FormItemWithValidation>
                </Col>
                <Col style={{textAlign: "center"}}>
                    <Button type="primary" style={{float: "left", marginTop: 15}} onClick={() => props.handleSubmit()} disabled={props.isSubmitting}>
                        <Spin spinning={props.isSubmitting}>
                            Dodaj listę
                        </Spin>
                    </Button>
                </Col>
            </Row>
        </Form>
    )
};

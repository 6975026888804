
// temporary solution for GRID - I've finally got to understand this.
// I'll think about cleaner solution later. So far it is RESPONSIVE!
export const colSizesTwoFirst = {
    xs: {span: 22, offset: 1},
    sm: {span: 22, offset: 1},
    md: {span: 10, offset: 1},
    lg: {span: 10, offset: 1},
    xl: {span: 10, offset: 1}
};

export const colSizesTwoSecond = {
    xs: {span: 22, offset: 1},
    sm: {span: 22, offset: 1},
    md: {span: 11, offset: 1},
    lg: {span: 11, offset: 1},
    xl: {span: 11, offset: 1}
};

export const colSizesTwoFirstBigger = {
    xs: {span: 22, offset: 1},
    sm: {span: 22, offset: 1},
    md: {span: 16, offset: 1},
    lg: {span: 16, offset: 1},
    xl: {span: 16, offset: 1}
};

export const colSizesTwoSecondSmaller = {
    xs: {span: 22, offset: 1},
    sm: {span: 22, offset: 1},
    md: {span: 6, offset: 1},
    lg: {span: 6, offset: 1},
    xl: {span: 6, offset: 1}
};
export const colSizes3 = {
    xs: {span: 23},
    sm: {span: 11, offset: 1},
    md: {span: 7, offset: 1},
    lg: {span: 7, offset: 1},
    xl: {span: 7, offset: 1}
};

export const colSizes4 = {
    xs: {span: 11, offset: 1},
    sm: {span: 11, offset: 1},
    md: {span: 5, offset: 1},
    lg: {span: 5, offset: 1},
    xl: {span: 5, offset: 1}
};

export const containerGrid = {
    xs: {span: 22, offset: 1},
    sm: {span: 22, offset: 1},
    md: {span: 20, offset: 2},
    lg: {span: 18, offset: 3},
    xl: {span: 16, offset: 4}
};


export const cardListGridSizes = {
    gutter: 20,
    xs: 1,
    sm: 1,
    md: 1,
    lg: 2,
    xl: 2,
    xxl: 3
};

export const cardApplicationListGridSizes = {
    gutter: 20,
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 4
};

import React, {useEffect, useState} from "react";
import {Button, Col, Empty, Icon, List, Row, Typography} from "antd";
import {fetchOwnOffers, IOfferListOffer} from "../../offer/actions/fetch_offer_list";
import {OfferOwnShort} from "../../offer/components/OfferOwnShort";
import {cardListGridSizes} from "../../offer/style.utils";
import {Link} from "react-router-dom";
import {appLink} from "../../app/utils/app_link";


interface IProps {
    userId: string | null;
}
const {Title} = Typography;

export const UseOwnOffersSection = (props: IProps) => {
    const [offers, setOffers] = useState<IOfferListOffer[]>([]);

    useEffect(() => {
        (async () => {
            if (props.userId) {
                const offers = await fetchOwnOffers(props.userId);
                setOffers(offers);
            }
        })();
    }, [props.userId]);

    const renderHeader = () => {
        return (
            <Row>
                <Col offset={1} span={9}>
                    <Title level={2} style={{
                        fontWeight: 600
                    }}>
                        Moje Oferty
                    </Title>
                </Col>
                <Link to={appLink.offer.add()}>
                    <Button style={{float: "right", marginRight: "4%", verticalAlign: "center"}} type="primary" size={"large"}><Icon type={"plus-circle"}/> Dodaj Ofertę</Button>
                </Link>
            </Row>
        )
    };

    return offers.length > 0 ? (
        <div id={"moje-oferty"}>
            {renderHeader()}

            <List
                grid={cardListGridSizes as any}
                style={{padding: "1% 4%"}}
                size="large"
                dataSource={offers}
                locale={{emptyText: <Empty style={{fontSize: 20}} description={`Nie dodałeś jeszcze żadnej oferty`}/>}}
                renderItem={(offer) => (
                    <OfferOwnShort offer={offer}/>
                )}
            />
        </div>
    ) : (
        <div>
            {renderHeader()}
            <Row type="flex" justify={"center"} align={"middle"} style={{marginTop: "5%", paddingBottom: "5%"}}>
                <Col xs={24}>
                    <Empty description={`Kliknij w "Dodaj Ofertę", aby dodać swoją pierwszą ofertę ;)`}/>
                </Col>
                <Link to={appLink.offer.add()}>
                    <Button style={{marginTop: "1%",}} type={"primary"}>Dodaj Ofertę</Button>
                </Link>
            </Row>
        </div>
    )

};

import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {LocaleProvider} from "antd";
import './App.css';
import 'antd/dist/antd.css';
import {Layout} from "./Layout";
import plPL from 'antd/es/locale-provider/pl_PL';


export const App: React.FC = () => {
    return (
        <BrowserRouter>
            <LocaleProvider locale={plPL}>
                <Layout/>
            </LocaleProvider>
        </BrowserRouter>
    );
};

import React from "react";

import {Button, Col, Form, Row, Spin} from "antd";
import {FormItemWithValidation} from "../../offer/components/FormItemWithValidation";
import {Field, FormikProps} from "formik";
import {IApplicationForm} from "./AddApplication";
import {InputNumberField} from "../../common/form-fields/InputNumberField";
import {TextAreaField} from "../../common/form-fields/TextAreaField";
import {InputField} from "../../common/form-fields/InputField";


interface IOwnProps {
    offerPrice: number | null;
}
export const AddApplicationForm = (props: IOwnProps & FormikProps<IApplicationForm>) => {
    const getFieldProps = (name: keyof IApplicationForm) => ({
        name,
        onChange: (value: string) => handleOnChange(value, name)
    });

    const getFieldWrapperProps = (name: keyof IApplicationForm) => ({
        error: props.errors[name],
        touched: props.touched[name]
    });

    const handleOnChange = (value: any, name: string) => {
        props.setFieldValue(name, value);
    };
    return (
        <Form>
            <Row type={"flex"}>
                {props.offerPrice === null && <Col span={24}>
                    <div className={"main-color"} style={{fontSize: 14}}>W ogłoszeniu nie zostało podane oferowane wynagrodzenie, w związku z tym proszę podaj swoje oczekiwania</div>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("price")}
                        label="Twoje oczekiwane wynagrodzenie"
                        required
                    >
                        <Field
                            {...getFieldProps("price")}
                            component={InputNumberField}
                            placeholder={"50zł"}
                        />

                    </FormItemWithValidation>
                </Col>
                }
                <Col span={24}>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("description")}
                        label="Wiadomość"
                    >
                        <Field
                            {...getFieldProps("description")}
                            component={TextAreaField}
                            placeholder={"Wiadomość dołączona do zgłoszenia"}
                        />
                    </FormItemWithValidation>
                </Col>
                <Col span={24}>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("email")}
                        label="Email"
                        required
                    >
                        <Field
                            {...getFieldProps("email")}
                            component={InputField}
                            placeholder={"Email do kontaktu"}
                        />
                    </FormItemWithValidation>
                </Col>
                <Col span={24}>
                    <FormItemWithValidation
                        {...getFieldWrapperProps("phone")}
                        label="Telefon"
                        required
                    >
                        <Field
                            style={{width: "100%"}}
                            {...getFieldProps("phone")}
                            component={InputNumberField}
                            placeholder={"Telefon do kontaktu"}
                        />
                    </FormItemWithValidation>
                </Col>

            </Row>
            <Button type="primary" style={{marginTop: 20}} onClick={() => props.handleSubmit()} disabled={props.isSubmitting}>
                <Spin spinning={props.isSubmitting}>Wyślij zgłoszenie </Spin>
            </Button>
        </Form>
    )
};

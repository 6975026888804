import React, {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {fetchApplicationDetail, IApplicationDetail} from "../actions/fetch_application_detail";

interface IProps extends RouteComponentProps<{ applicationId: string, offerId: string }> {

}

export const ApplicationView = (props: IProps) => {
    const [application, setApplication] = useState<IApplicationDetail | null>(null);

    useEffect(() => {
        (async () => {
            const applicationDetail = await fetchApplicationDetail(props.match.params.applicationId, props.match.params.offerId) as IApplicationDetail;
            setApplication(applicationDetail);
        })();
    }, []);

    return application ? <div>{application.price}</div> : null;
};

export const collectionPath = {
    offers: "offers",
    offersApplications: ({offerId}: {offerId: string}) => `offers/${offerId}/applications`,
    offersPrivate: ({offerId}: {offerId: string}) => `offers/${offerId}/offers-private`,
    offersPreview: "offers-preview",
    users: "users"
};

export const collectionGroupPath = {
    applications: "applications"
};

import React from "react";
import moment, {Moment} from "moment";
import {Badge, Card, Col, List, Row, Typography} from "antd";
import {Link} from "react-router-dom";
import {IApplicationListApplication} from "../../application/actions/fetch_applications";
import {appLink} from "../../app/utils/app_link";
import {getApplicationColor} from "../../app/utils/getApplicationColors";
import {upperFirst} from "lodash";
import {getOfferTypeDisplayName} from "../../firebase/fields/OfferType";
import {getApplicationStatusDisplayName} from "../../firebase/fields/ApplicationStatus";

const {Paragraph, Text} = Typography;


interface IProps {
    application: IApplicationListApplication;
}
export const UserOwnApplication = (props: IProps) => {
    const {application} = props;

    const renderBadge = () => {
        const statusObj = getApplicationStatusDisplayName(application.status);

        return <div><Badge status={statusObj.name} text={statusObj.label}/></div>
    };

    const renderDescription = (date: Moment | null, time: Moment | null) => {
        return (
            <Row type={"flex"}>
                <Col style={{fontSize: 16}} xs={{span: 22}} sm={{span: 12}} md={{span: 22}} >
                    {upperFirst(application.offerCity)} {getOfferTypeDisplayName(application.offerType)}
                </Col>
                <Col style={{fontSize: 16}} xs={{span: 22}} sm={{span: 12}} md={{span: 22}}>
                    {date && date.format("DD.MM.YYYY")} {time && `${time.format("HH:mm")}`}
                </Col>
            </Row>
        )
    };

    const renderCardMeta = (date: Moment | null, time: Moment | null) => {
        return (
            <Card.Meta
                description={renderDescription(date, time)}
            />
        )
    };


    const {backgroundColor} = getApplicationColor(application.status);
    const date = application.offerDate ? moment(application.offerDate.toDate()) : null;
    const time = application.offerTime ? moment(application.offerTime.toDate()) : null;

    return (
        <List.Item style={{height: 150, overflow: "hidden", fontSize: 16}}>
            <Link to={appLink.offer.detail.base({offerId: application.offer})}>
                <Card title={renderCardMeta(date, time)}
                      hoverable
                      style={{height: 150, backgroundColor}}
                      bodyStyle={{paddingTop: 0, paddingBottom: 0}}
                      extra={<Col xs={{span: 0}} sm={{span: 24}}>{renderBadge()}</Col>}
                >
                    <Paragraph ellipsis={{rows: 2}}>
                        <Text style={{fontSize: 16}}>Cena: {application.offerPrice || application.price} zł</Text>
                        <br/>
                        <Text style={{fontSize: 16}}>Wiadomość: {application.description}</Text>
                    </Paragraph>
                </Card>
            </Link>
        </List.Item>
    )
};

import {filter, first} from "lodash";
import {firestoreDb} from "../../firebase/initialize_firebase_app";
import {collectionPath} from "../../firebase/collection_path";
import {IUserPreference} from "../../firebase/fields/IUserPreference";
import {IUserUpdate} from "../../firebase/documents/IUser";


export const updateUserProfile = (userId: string, updateData: Partial<IUserUpdate>): Promise<boolean> => {
    const docRef = firestoreDb.collection(collectionPath.users).doc(userId);
    return docRef
        .update(updateData)
        .then((res: void) => {
            return true;
        })
        .catch(e => {
            console.error("updateUserProfile", userId, updateData, e);
            throw e;
        });
};

export const createPreference = (userId: string, preferences: IUserPreference[], preference: IUserPreference): Promise<IUserPreference[]> => {
    const newPreferences = [preference, ...preferences];
    return firestoreDb
        .collection(collectionPath.users)
        .doc(userId)
        .update({
            preferences: newPreferences
        })
        .then(() => newPreferences)
        .catch(e => {
            console.error("createPreference", userId, newPreferences, e);
            throw e;
        });
};

export const removePreference = (userId: string, preferences: IUserPreference[], preference: IUserPreference): Promise<IUserPreference[]> => {
    const newPreferences = filter(preferences, p => !(p.region === preference.region && p.type === preference.type));
    return firestoreDb
        .collection(collectionPath.users)
        .doc(userId)
        .update({
            preferences: newPreferences
        })
        .then(() => newPreferences)
        .catch(e => {
            console.error("removePreference", userId, newPreferences, e);
            throw e;
        });
};

const MAX_DEVICES_SIZE = 5;
export const saveDeviceToUser = async (userId: string, devices: string[], device: string): Promise<string[]> => {
    if (device === first(devices)) {
        return devices;
    }
    const newDevices = [device, ...filter(devices, d => d !== device)].slice(0, MAX_DEVICES_SIZE);
    return firestoreDb
        .collection(collectionPath.users)
        .doc(userId)
        .update({
            devices: newDevices
        })
        .then(() => newDevices)
        .catch(e => {
            console.error("saveDeviceToUser", userId, newDevices);
            throw e;
        });
};

import React from "react";
import {Avatar, Card, Icon, List, Typography} from "antd";
import {
    getExperienceDisplayName,
    getLawyerTypeDisplayName,
    OfferLawyerType
} from "../../firebase/fields/OfferLawyerType";
import {removeHttpsFromUrl} from "../../app/utils/remove_https";

interface IProps {
    displayName: string | null;
    photoURL: string | null;
    contactMail: string | null,
    contactPhone: string | null,
    lawyerType: OfferLawyerType | null,
    number: string | null;
    invoice: boolean;
    experience: number | null;
    facebookProfileUrl?: string;
    linkedInProfileUrl?: string;
}

const {Paragraph} = Typography;

export const UserDetail = (props: IProps) => {
    const renderCardMeta = () => {
        return (
            <Card.Meta
                avatar={
                    <Avatar src={props.photoURL ? props.photoURL : ""}/>
                }
                title={props.displayName}
                description={<>{props.lawyerType && getLawyerTypeDisplayName(props.lawyerType)} {props.experience && `z ${getExperienceDisplayName(props.experience)} stażem`} </>}
            />
        )
    };

    return (
        <List.Item style={{width: "100%", overflow: "hidden"}}>
            <Card title={renderCardMeta()}
                  style={{width: "100%", color: "#626d8e"}}
                  extra={<>
                      {props.facebookProfileUrl && <a href={`//${removeHttpsFromUrl(props.facebookProfileUrl)}`} target={"_blank"}><Icon type={"facebook"} style={{fontSize: 24, color: "#626d8e", cursor: "pointer"}}/></a>}
                      {props.linkedInProfileUrl && <a href={`//${removeHttpsFromUrl(props.linkedInProfileUrl)}`} target={"_blank"}><Icon type={"linkedin"} style={{fontSize: 24, color: "#626d8e", cursor: "pointer"}}/></a>}
                 </>}
            >
                <Paragraph><Icon type={"mail"}/> <span className={"word-break"}>{props.contactMail}</span></Paragraph>
                <Paragraph><Icon type={"phone"}/> {props.contactPhone}</Paragraph>
                <Paragraph><Icon type={"read"}/> {props.number ? props.number : "Brak wpisu"}</Paragraph>
                <Paragraph>Faktura: {props.invoice ? <Icon type={"like"} style={{fontSize: 24}}/> : <Icon type={"dislike"} style={{fontSize: 24}}/>}</Paragraph>

            </Card>
        </List.Item>
    )
};

import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {Header} from "./components/Header";
import {BodyRoutes} from "./BodyRoutes";
import {initFirebaseMessagingListener} from "./utils/init_firebase_messaging_listener";
import {useAuth} from "../auth/components/use_auth";
import {fetchUser} from "../user/actions/fetch_user_actions";
import {IUser} from "../firebase/documents/IUser";
import {updateFirebaseMessagingDevices} from "./utils/update_firebase_messaging_devices";
import {FooterC} from "./components/Footer";

interface IUserProfileContext {
    userProfile: IUser | null;
    setUserProfile: Dispatch<SetStateAction<IUser | null>> | null;
}
export const UserProfileContext = React.createContext<IUserProfileContext>({userProfile: null, setUserProfile: null});

interface IProps extends RouteComponentProps {}

export const Layout = withRouter((props: IProps) => {

    /**
     * User and profile
     */

    const {isAuthPending, userData, logout} = useAuth();
    const isAuthenticated = !isAuthPending && !!userData;
    const isAnonymous = !isAuthPending && !userData;

    const [userProfile, setUserProfile] = useState<IUser | null>(null);
    useEffect(() => {
        if (userData == null && userProfile != null) { // logout
            setUserProfile(null);
            return;
        }
        if (userData != null && userProfile == null) { // login
            // TODO: error occurs when user logout during following chain of actions - maybe use enable-logout state?
            fetchUser(userData.uid)
                .then(profile => {
                    updateFirebaseMessagingDevices(profile.uid, profile.devices);
                    setUserProfile(profile);
                });
            return;
        }
    }, [userData, userProfile]);

    /**
     * Foreground messaging
     */

    useEffect(() => {
        initFirebaseMessagingListener(url => props.history.push(url));
    }, []);

    /**
     * Render
     */

    const handleLogout = () => {
        logout();
        props.history.push("/");
    };

    return (
        <UserProfileContext.Provider value={{userProfile, setUserProfile}}>
            <div style={{minHeight: "100vh", position: "relative"}}>
                <Header userProfile={userProfile} logout={handleLogout}/>
                <article style={{paddingBottom: "2.5rem"}}>
                    <BodyRoutes
                        location={props.location} userData={userData} isAuthPending={isAuthPending}
                        isAuthenticated={isAuthenticated} isAnonymous={isAnonymous}
                    />
                </article>
                <FooterC/>
            </div>
        </UserProfileContext.Provider>
    );
});

import React from "react";
import {Empty, List} from "antd";
import {IApplicationListApplication} from "../actions/fetch_applications";
import {ApplicationItem} from "./ApplicationItem";
import {OfferStatus} from "../../firebase/fields/OfferStatus";
import {ApplicationStatus} from "../../firebase/fields/ApplicationStatus";


interface IProps {
    applications: IApplicationListApplication[] | null;
    offerId: string;
    offerStatus: OfferStatus;
    offerPrice: number | null;
    onSuccessfulAccept: () => void;
    showContactData?: boolean;
}

export const ApplicationsList = (props: IProps) => {

    if (props.applications == null) {
        return null;
    }


    return <List
        grid={{
            gutter: 20,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
            xxl: 1,
        }}
        size="large"
        dataSource={props.applications}
        locale={{emptyText: <Empty style={{fontSize: 20}} description="Obecnie nie ma zgłoszeń na tę ofertę"/>}}
        renderItem={(application) => (
            <ApplicationItem
                application={application}
                offerPrice={props.offerPrice}
                offerId={props.offerId}
                enableAcceptButton={props.offerStatus === OfferStatus.PUBLISHED && application.status === ApplicationStatus.PENDING}
                onSuccessfulAccept={props.onSuccessfulAccept}
                showContactData={props.showContactData}
            />
        )}
    />
};


import {Input} from "antd";
import React from "react";
import {FieldProps} from "formik";

const {TextArea} = Input;


interface IProps extends FieldProps<any> {
    placeholder: string;
    autosize: { minRows: number; maxRows: number; };
}

export const TextAreaField = (props: IProps) => {
    const {placeholder, autosize} = props;

    return (
        <TextArea {...props.field} placeholder={placeholder} autosize={autosize}/>
    )
};

import React from "react";
import {AutoComplete} from "antd";
import {FieldProps} from "formik";
import {map} from "lodash";
import {IValueLabel} from "../../offer/components/OfferFormOptions";

const {Option} = AutoComplete;

interface IProps extends FieldProps<any> {
    placeholder: string;
    onChange: () => void;
    data: IValueLabel[];
    backfill?: boolean;
    defaultActiveFirstOption?: boolean;
}

export const AutocompleteField = (props: IProps) => {
    const {placeholder, data, backfill} = props;

    return (
        <AutoComplete
            {...props.field}
            dataSource={map(data, city => <Option key={city.value} value={city.value}>{city.label}</Option>)}
            placeholder={placeholder}
            backfill={backfill}
            onChange={props.onChange}
            filterOption={true}
        />
    )
};

import {useCallback, useEffect, useState} from "react";
import firebase from "firebase/app";
type User = firebase.User;



export const useAuth = () => {
    // auth user
    const [isAuthPending, setIsAuthPending] = useState(true);
    const [userData, setUserData] = useState<User | null>(null);
    useEffect(() => {
        // handle authentication
        const auth = firebase.auth();
        auth.onAuthStateChanged((user: User | null) => {
            setUserData(user);
            setIsAuthPending(false);
        });
    }, []);
    // logout user
    const logout = useCallback(() => {
        setIsAuthPending(true);
        firebase.auth().signOut();
    }, []);
    // API
    return {isAuthPending, userData, logout};
};

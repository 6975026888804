import React from 'react';
import {find, some} from "lodash";
import PlacesAutocomplete, {geocodeByPlaceId} from 'react-places-autocomplete';
import {Col, Input, Row} from "antd";
import {FieldProps} from "formik";
import {IOfferFormCity} from "../../offer/models/IOfferForm";
import {fromPlacesVoivodeshipToOfferRegion} from "../../firebase/fields/OfferRegion";


interface IProps extends FieldProps<IOfferFormCity | null> {
    onChange: (city: IOfferFormCity | null) => void;
}

interface IState {
    input: string;
}

export class LocationSearchInput extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {input: props.field.value && props.field.value.name || ""};
    }

    private inputChange = (input: string) => this.setState({input});
    private inputReset = () => this.setState({input: ""});

    private getSlicedCityName = (input: string) => input ? input.split(", ").slice(0,-1)[0] : "";
    private handleSelect = (input: string, placeId: string | null) => {
        if (placeId == null) {
            return; // place not selected
        }
        const slicedCityName = this.getSlicedCityName(input);
        this.inputChange(slicedCityName);
        geocodeByPlaceId(placeId)
            .then((results: google.maps.GeocoderResult[]) => {
                if (results.length === 0) {
                    throw new Error(`LocationSearchInput: geocodeByPlaceId responded with no results for ${placeId}`);
                }
                const region = results[0];
                const voivodeship = find(region.address_components, elem => some(elem.types, type => type === "administrative_area_level_1"));
                if (voivodeship == null) {
                    throw new Error(`LocationSearchInput: geocodeByPlaceId responded with no voivodeship for ${placeId}`);
                }
                const formCity: IOfferFormCity = {
                    name: slicedCityName, // both name and region are polish based
                    id: region.place_id,
                    region: fromPlacesVoivodeshipToOfferRegion(voivodeship.long_name)
                };
                this.props.onChange(formCity);
            });
    };

    public render() {
        const searchOptions = {
            language: "pl",
            componentRestrictions: {country: "pl"},
            types: ['(cities)']
        };

        return (
            <PlacesAutocomplete
                debounce={300}
                value={this.state.input}
                searchOptions={searchOptions}
                highlightFirstSuggestion
                onChange={this.inputChange}
                onSelect={this.handleSelect}
            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => {
                    const activeSuggestion = find(suggestions, suggestion => suggestion.active);

                    const onKeyDownHandler = async (e: React.KeyboardEvent<HTMLDivElement>) => {
                        if (e.key === "Escape") {
                            this.inputReset();
                            this.props.onChange(null);
                        }
                        if (e.key === "Tab") {
                            if (activeSuggestion || suggestions.length) {
                                this.handleSelect(activeSuggestion ? activeSuggestion.description : suggestions[0].description, suggestions[0].id)
                            }
                        }
                        else {
                            getInputProps().onKeyDown(e);
                        }

                    };
                    return (
                        <Row className="global-search-wrapper">
                            <Input
                                {...getInputProps({
                                    placeholder: 'Wpisz miasto...',
                                    className: 'ant-select-search ant-select-search--inline',
                                })}
                                onKeyDown={(e) => {
                                    onKeyDownHandler(e)
                                }}
                            />
                            <Col span={24} className="global-search" style={{position: "absolute", zIndex: 100}}>
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? {backgroundColor: '#e6f7ff', cursor: 'pointer'}
                                        : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                        >
                                            <span>{this.getSlicedCityName(suggestion.description)}</span>
                                        </div>
                                    );
                                })}
                            </Col>
                        </Row>
                    )
                }}
            </PlacesAutocomplete>
        );
    }
}

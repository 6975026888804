export enum OfferType {
    OTHER = "other",
    SUBSTITUTION = "substitution",
    PHOTOCOPY = "photocopy"
}

export const getOfferTypeDisplayName = (type: OfferType): string => {
    switch (type) {
        case OfferType.SUBSTITUTION:
            return "Zastępstwo";
        case OfferType.PHOTOCOPY:
            return "Fotokopie";
        case OfferType.OTHER:
            return "Inne";
        default:
            throw new Error(`Invalid type: ${type}`);
    }
};

export const getOfferTypeDisplayNamePlural = (type: OfferType): string => {
    switch (type) {
        case OfferType.SUBSTITUTION:
            return "Zastępstwa";
        case OfferType.PHOTOCOPY:
            return "Fotokopie";
        case OfferType.OTHER:
            return "Inne";
        default:
            throw new Error(`Invalid type: ${type}`);
    }
};

export const getOfferTypeDisplayNameWhatPlural = (type: OfferType): string => {
    switch (type) {
        case OfferType.SUBSTITUTION:
            return "zastępstw";
        case OfferType.PHOTOCOPY:
            return "fotokopii";
        case OfferType.OTHER:
            return "innych zleceń";
        default:
            throw new Error(`Invalid type: ${type}`);
    }
};

export const isOfferTypeValid = (type: string): boolean => {
    switch (type) {
        case OfferType.SUBSTITUTION:
        case OfferType.PHOTOCOPY:
        case OfferType.OTHER:
            return true;
        default:
            return false;
    }
};

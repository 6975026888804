import {collectionPath} from "../../firebase/collection_path";
import {firestoreDb} from "../../firebase/initialize_firebase_app";
import {IApplication, IApplicationCreate} from "../../firebase/documents/IApplication";
import {IApplicationDetail} from "./fetch_application_detail";
import {waitForData} from "../../firebase/actions/wait_for_data";
import {ApplicationStatus} from "../../firebase/fields/ApplicationStatus";


export const createApplication = (userId: string, offerId: string, application: IApplicationCreate): Promise<IApplicationDetail | void> => {
    const docRef = firestoreDb.collection(collectionPath.offersApplications({offerId})).doc(userId);
    return docRef
        .set(application)
        .then(async () => {
            const data = await waitForData("createApplication")<IApplication>(docRef, data => data.status === ApplicationStatus.PENDING);
            return {...data, id: docRef.id} as IApplicationDetail;
        })
        .catch(e => {
            console.error("createApplication", userId, offerId, application, e);
            throw e;
        });
};

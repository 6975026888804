import React, {useEffect, useState} from "react";
import {Empty, List} from "antd";
import {IUserPreference} from "../../firebase/fields/IUserPreference";
import {fetchFilteredOfferList, IOfferListOffer} from "../../offer/actions/fetch_offer_list";
import {OfferListItem} from "../../offer/components/OfferListItem";
import {cardListGridSizes} from "../../offer/style.utils";
import {getOfferTypeDisplayNameWhatPlural} from "../../firebase/fields/OfferType";
import {offerRegionMapWhere} from "../../firebase/fields/OfferRegion";
import {IUser} from "../../firebase/documents/IUser";


interface IProps extends IUserPreference {
    user: IUser | null;
    applicationsIds: string[];
}

export const UserPreference = (props: IProps) => {

    const [filteredOffers, setOffers] = useState<IOfferListOffer[]>([]);
    useEffect(() => {
        (async () => {
            const offers = await fetchFilteredOfferList(props.region, props.type);
            setOffers(offers);
        })();
    }, []);
     return (
        <List
            grid={cardListGridSizes as any}
            style={{padding: "1% 4%"}}
            size="large"
            dataSource={filteredOffers}
            locale={{emptyText: <Empty style={{fontSize: 20}} description={`Obecnie nie ma ofert dot. ${getOfferTypeDisplayNameWhatPlural(props.type)} ${offerRegionMapWhere[props.region]}`}/>}}
            renderItem={(offer) => (
                <OfferListItem offer={offer} user={props.user} applicationsIds={props.applicationsIds}/>
            )}
        />
    )
};


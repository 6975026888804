import React, {useEffect} from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import firebase from "firebase/app";
import {Col, Layout, Row, Typography} from "antd";
import {AuthButtons} from "../components/AuthButtons";
import {containerGrid} from "../../offer/style.utils";
import {FlowStepsDescription} from "../../homepage/components/FlowStepsDescription";
type User = firebase.User;

const {Title, Text} = Typography;


interface IProps extends RouteComponentProps {
    userData: User | null;
    isAnonymous: boolean;
    successUrl?: string;
    offerId?: string;
    title?: string;
}

export const LoginView = withRouter((props: IProps) => {
    const {successUrl, offerId, title} = props;

    useEffect(() => {
        if (offerId) {
            return props.history.push(successUrl!);
        }
        if (props.userData) {
            props.history.push('/');
        }
    }, [props.userData]);

    return (
        <Layout style={{height: "100%"}}>
            <Row style={{marginTop: "10%"}}>
                <Col className={"sign-in"} {...containerGrid}>
                    {props.isAnonymous && (
                        <>
                            {<Title style={{textAlign: "center"}}>{title || "Zaloguj się"}</Title>}
                            <div style={{textAlign: "center", marginTop: "1%"}}>
                                <Title level={4}>Dlaczego dostępny jest tylko Facebook?</Title>
                                <Text>
                                    Wierzymy, że weryfikacja przez największy portal społecznościowy, pozwoli uzyskać
                                    wysoki poziom wiarygodności użytkowników, a co za tym idzie - ograniczyć ilość
                                    oszustw w tego typu usługach.
                                </Text>
                            </div>
                            <AuthButtons successUrl={successUrl}/>
                        </>
                    )}
                    {props.location.hash && <Title style={{textAlign: "center"}}>Jak działa portal SubstytucjeProcesowe</Title>}
                    <FlowStepsDescription type={props.location.hash}/>
                </Col>
            </Row>
        </Layout>
    )
});

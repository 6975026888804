import {notification} from "antd";
import {messaging} from "../../firebase/initialize_firebase_app";
import {CURRENT_SITE} from "../constants/sites_and_domains";


interface IMessagePayload {
    collapse_key: string;
    from: string; // senderId (`messagingSenderId` in ServiceWorker)
    fcmOptions: {
        link: string;
    };
    notification: {
        body: string;
        title: string;
    };
    priority: "normal"
}

export const initFirebaseMessagingListener = (onLinkClick: (url: string) => void) => {
    safelyRequestPermission().then(permission => {
        // handle foreground messages
        permission && messaging && messaging.onMessage((payload: IMessagePayload) => {
            if (!(payload && payload.notification && payload.notification.title && payload.fcmOptions && payload.fcmOptions.link)) {
                return console.log("onMessage: invalid payload");
            }
            const link = payload.fcmOptions.link.replace(CURRENT_SITE, "");
            notification.info({
                message: payload.notification.title,
                description: payload.notification.body,
                duration: 10,
                onClick: () => onLinkClick(link),
                style: {cursor: "pointer"}
            });
        });
    });
};

export const safelyRequestPermission = async () => {
    if (!("Notification" in window)) {
        return console.log("safelyRequestPermission: no Notification interface");
    }
    const request = Notification.requestPermission();
    if (request == null || request.then == null) {
        return console.log("safelyRequestPermission: invalid request");
    }
    return request
        .then(permission => {
            if (permission !== "granted") {
                return;
            }
            return permission;
        })
        .catch(() => {
            console.log("safelyRequestPermission: request catch")
        });
};

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, Col, notification, Popconfirm, Row, Spin, Typography} from "antd";
import {Formik, FormikActions, FormikErrors, FormikProps} from 'formik';
import moment from "moment";
import {OfferDetailForm} from "./OfferDetailForm";
import {IOfferForm} from "../models/IOfferForm";
import {IOfferFormJson, IOfferUpdate} from "../../firebase/documents/IOffer";
import {closeOffer, updateOffer} from "../actions/update_offer_actions";
import {fromJsonValues, toJsonValues} from "../form.utils";
import {fetchOfferDetail, fetchOfferPrivateDetail} from "../actions/fetch_offer_detail";
import {OfferStatus} from "../../firebase/fields/OfferStatus";
import {containerGrid} from "../style.utils";
import {Messages} from "../../app/constants/notification_messages";
import {RouteViewTypeContext} from "../../app/BodyRoutes";


interface IProps {
    userId: string;
    offerId: string;
    redirectToOffer: () => void;
}
const {Title} = Typography;

export const OfferEdit = (props: IProps) => {
    const [initialValues, setInitialValues] = useState<IOfferForm | null>(null);
    const [user, setUser] = useState<string | null>(null);
    const [isClosing, setClosing] = useState(false);
    const [offerStatus, setOfferStatus] = useState("");
    const routeViewType = useContext(RouteViewTypeContext);
    useEffect(() => {
        (async () => {
            const offerPublic = await fetchOfferDetail(props.offerId);
            const offerPrivate = await fetchOfferPrivateDetail(props.offerId);
            if (offerPublic == null || offerPrivate == null) {
                routeViewType.enableSite404();
                return;
            }
            const offerData = {...offerPublic, ...offerPrivate};
            setOfferStatus(offerData.status);
            setInitialValues(fromJsonValues(offerData));
            setUser(offerData.user);
        })();
    }, []);
     const submitValidation = useCallback((values: IOfferForm): FormikErrors<IOfferForm> => {
        const errors: FormikErrors<IOfferForm> = {};
        if (values.city == null) {
            errors.city = "Pole wymagane";
        }
        if (values.type == null) {
            errors.type = "Pole wymagane";
        }
        if (!values.addressName) {
            errors.addressName = "Pole wymagane";
        }
        // date
        if (values.date == null) {
            errors.date = 'Pole wymagane';
        }
        else if (values.date.isBefore(moment(), "days")) {
            errors.date = "Termin musi być datą dzisiejszą lub przyszłą";
        }
        else if (values.date.isAfter(moment().add(30, "days"), "days")) {
            errors.date = "Termin nie może przekraczać 30 dni";
        }
        // time
        if (values.time != null && values.date != null) {
            if (values.date.isSame(moment(), "days")) {
                if (values.time.isBefore(moment().subtract(5, "minutes"), "minutes")) {
                    errors.time = "Godzina i data muszą odnosić się do przyszłości";
                }
            }
        }

        return errors;
    }, []);

    if (!initialValues) {
        return null;
    }

    if (user !== props.userId) {
        return <div>Oferty mogą być edytowane tylko przez ich autora.</div>
    }
    const confirmCloseOffer = async () => {
        setClosing(true);
        await closeOffer(props.offerId, {
            user: props.userId,
            status: OfferStatus.CLOSING
        });
        setClosing(false);
        props.redirectToOffer();
    };

    return (
        <Row style={{padding: "2% 0", height: "100%"}}>
            <Col {...containerGrid}>
                <Row style={{paddingBottom: "1%"}}>
                    <Col>
                    <Title level={3} style={{display: "inline"}}>Edytuj Ofertę</Title>
                        {offerStatus === OfferStatus.PUBLISHED && (<Popconfirm
                            title={"Czy na pewno chcesz zamknąć ofertę?"}
                            onConfirm={(e) => {
                                confirmCloseOffer();
                            }}
                            okText="Tak"
                            cancelText="Nie"
                        >
                            <Button style={{float: "right"}} type={"danger"} disabled={isClosing}>
                                <Spin spinning={isClosing}>
                                    Zakończ ofertę
                                </Spin>
                            </Button>
                        </Popconfirm>
                        )}
                    </Col>
                </Row>
                <Formik
                    initialValues={initialValues}
                    validate={submitValidation}
                    onSubmit={async (values: IOfferForm, actions: FormikActions<IOfferForm>) => {
                        actions.setSubmitting(true);
                        const offerFormJson: IOfferFormJson | null = toJsonValues(values, field => actions.setFieldError(field, "Błąd"));
                        if (offerFormJson == null) {
                            return actions.setSubmitting(false);
                        }
                        const offerUpdate: IOfferUpdate = {
                            ...offerFormJson,
                            user: props.userId,
                            status: OfferStatus.UPDATING
                        };
                        const data = await updateOffer(props.offerId, offerUpdate);
                        if (data) {
                            notification.success(Messages.offer.edit);
                            props.redirectToOffer();
                        }
                        else {
                            notification.error({message: "Błąd!", description: "Zapis oferty nie powiódł się."});
                        }
                        actions.setSubmitting(false);
                    }}
                    render={(renderProps: FormikProps<IOfferForm>) => {
                        return (
                            <OfferDetailForm buttonText="Edytuj ofertę" {...renderProps} />
                        )
                    }}
                />

            </Col>
        </Row>
    );
};

import React, {useContext} from "react";
import {RouteComponentProps} from "react-router";
import {Layout} from "antd";
import {OfferAdd} from "../components/OfferAdd";
import {UserProfileContext} from "../../app/Layout";


interface IProps extends RouteComponentProps {
}

export const OfferAddView = (props: IProps) => {
    const {userProfile} = useContext(UserProfileContext);
    return (
        <Layout>
            {userProfile && <OfferAdd
                userEmail={userProfile.contactMail}
                userPhone={userProfile.contactPhone}
                userId={userProfile.uid}
                onSuccessfulAdd={() => props.history.push("/oferty/")}
            />}
        </Layout>
    );
};

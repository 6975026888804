import React from "react";
import {Form} from "antd";
import {FormikErrors, FormikTouched} from "formik";

interface IProps {
    error?: string | FormikErrors<{}>;
    label: string;
    touched?: boolean | FormikTouched<{}>;
    required?: boolean;
    children: any;
}

export const FormItemWithValidation = (props: IProps) => {
    const {touched, label, error = "", required} = props;

    return (
        <Form.Item
            label={label}
            required={required}
            help={!!touched && error}
            validateStatus={(!!touched && error) ? "error" : "success"}
        >
            {props.children}
        </Form.Item>

    )
}

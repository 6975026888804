import React from "react";
import {Avatar, Col, Layout, Row, Typography} from "antd";

const {Content} = Layout;
const {Paragraph, Title} = Typography;

export const AboutUsView = () => {
    return (
        <Layout style={{padding: "4%"}}>
            <Content>
                <Row className={"faq-row"}>
                    <Row type={"flex"} justify={"center"}>
                        <Col style={{margin: "2%", flexDirection: "column"}}>
                            <Avatar size={240} src={`${process.env.PUBLIC_URL}/ms-240x240.png`} alt={"Michał"}/>
                            <div className="main-color" style={{textAlign: "center"}}>Michał</div>
                        </Col>
                        <Col style={{margin: "2%", flexDirection: "column"}}>
                            <Avatar size={240} src={`${process.env.PUBLIC_URL}/tc-240x240.png`} alt={"Tomek"}/>
                            <div className="main-color" style={{textAlign: "center"}}>Tomek</div>
                        </Col>
                    </Row>
                    <Title level={2}>O Nas</Title>
                    <Paragraph>
                        Jesteśmy programistami aplikacji webowych z wieloletnim doświadczeniem, dla których tworzenie
                        nowych rozwiązań i przenoszenie ich do Internetu jest nie tylko zawodem ale także pasją. W
                        swojej pracy stawiamy na innowacyjność rozwiązań, konsultację wdrażanych pomysłów i wytrwałość
                        w realizacji celu. Wierzymy, że społeczność jest w stanie usprawnić swoje procesy, przy czym
                        potrzebuje jedynie odpowiednich narzędzi.
                    </Paragraph>
                    <Title level={2}>Koncepcja</Title>

                    <Paragraph>
                        Jak często bywa, to znajomi i przyjaciele są inspiracją dla najlepszych pomysłów. Tak było i tym
                        razem, kiedy to od zaprzyjaźnionych prawników usłyszeliśmy o problemach w znalezieniu
                        substytucji w procesie sądowym w innym mieście. Dowiedzieliśmy się w jaki sposób do tej pory
                        wyglądało poszukiwanie odpowiedniej osoby, poprzez telefonowanie po znajomych lub szukanie na
                        forach czy grupach społecznościowych. Żadne z dotychczasowych rozwiązań nie mogło spełnić
                        wszystkich wymagań ponieważ nie zostało zaprojektowane z myślą o tym konkretnym przypadku.
                    </Paragraph>
                    <Title level={2}>Doświadczenie</Title>

                    <Paragraph>
                        Z naszym doświadczeniem i pomysłami dookoła doszliśmy do wniosku, że trzeba poświęcić kilka (lub
                        kilkadziesiąt) wieczorów i coś z tym zrobić. Posiadamy praktyczną wiedzę o projektowaniu
                        aplikacji webowych, pracujemy z najnowszymi technologiami dostępnymi w przeglądarkach
                        internetowych i wiarygodnymi rozwiązaniami serwerowymi, które posiadają szerokie wsparcie
                        techniczne i każdego dnia używane są przez miliony użytkowników na całym świecie. To wszystko
                        sprawiło, że stosunkowo niewielkim kosztem byliśmy w stanie stworzyć solidny i bezpieczny
                        portal, który został skrojony na miarę potrzeb.

                    </Paragraph>
                    <Title level={2}>SubstytucjeProcesowe</Title>

                    <Paragraph>
                        Skoro jedni prawnicy poszukują substytucji, a inni chcą się ich podjąć, to należy obu grupom
                        ułatwić życie i w możliwie krótkim czasie znaleźć idealne połączenie. Tworzący ogłoszenie mają
                        do dyspozycji wygodny formularz, gdzie podają miasto i typ oferty. Natomiast zainteresowani
                        ogłoszeniami definiują swoje listy preferencji, dzięki którym zobaczą tylko interesujące ich
                        oferty.
                        Rewolucyjną sprawą jest precyzyjny system powiadomień obsługujący zarówno komputery
                        jak i smartfony. Aktywuje się on automatycznie przy wejściu na stronę i wymaga od użytkownika
                        jedynie zgody na otrzymywanie powiadomień.
                        Twoje ogłoszenie trafi do osób faktycznie nim zainteresowanych, od razu otrzymasz powiadomienie
                        kiedy któraś z nich zdecyduje się podjąć ofertę i jednym kliknięciem zaakceptujesz najlepsze
                        zgłoszenie i prześlesz kontrahentowi wymagane informacje.
                    </Paragraph>

                    <Paragraph>
                        Korzystanie z portalu jest i będzie darmowe, ponieważ zależy nam na stworzeniu szerokiej
                        społeczności wspierającej rzetelne rozwiązania. Nazwa wskazuje co jest naszym głównym celem,
                        jednak jesteśmy otwarci na propozycje, bo wierzymy, że im więcej głów tym więcej dobrych
                        pomysłów.
                    </Paragraph>


                </Row>
            </Content>
        </Layout>
    );
};

import {OfferLawyerType} from "../../firebase/fields/OfferLawyerType";
import {OfferType} from "../../firebase/fields/OfferType";

export interface IValueLabel {
    label: string;
    value: string;
}

export const OfferTypeOptions = [
    {value: OfferType.SUBSTITUTION, label: "Zastępstwo"},
    {value: OfferType.PHOTOCOPY, label: "Fotokopie akt"},
    {value: OfferType.OTHER, label: "Inne"}
];

export const LawyerTypeOptions = [
    {value: OfferLawyerType.ADWOKAT, label: "Adwokat"},
    {value: OfferLawyerType.RADCA, label: "Radca prawny"},
    {value: OfferLawyerType.APLIKANT_ADWOKACKI, label: "Aplikant adwokacki"},
    {value: OfferLawyerType.APLIKANT_RADCOWSKI, label: "Aplikant radcowski"},
];

import {ApplicationStatus} from "../../firebase/fields/ApplicationStatus";

export const getApplicationColor = (status: ApplicationStatus) => {
        switch (status) {
            case ApplicationStatus.PENDING:
                return {
                    border: "blue",
                    backgroundColor: "#1890ff0f"
                };
            case ApplicationStatus.ACCEPTED:
                return {
                    border: "green",
                    backgroundColor: "#52c41a0f"

                };
            case ApplicationStatus.REJECTED:
                return {
                    border: "red",
                    backgroundColor: "#f5222d08"
                };
            default:
                return {
                    border: "",
                    backgroundColor: ""
                }
        }
    };

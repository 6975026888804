import React from "react";
import {Icon, Layout, Row, Typography} from "antd";
import {RouteComponentProps} from "react-router";
import {Link} from "react-router-dom";
import {appPath} from "../../app/utils/app_path";

const {Content} = Layout;
const {Paragraph, Title} = Typography;

interface IProps extends RouteComponentProps {
}

export const FAQView = (props: IProps) => {
    return (
        <Layout style={{padding: "4%"}}>
            <Content>
                <Row className={"faq-row"}>
                    <Title>Najczęściej zadawane pytania</Title>

                    <Title level={3}>1. Czy dostep do aplikacji jest płatny?</Title>
                    <Paragraph>
                        Korzystanie z serwisu jest zupełnie <b>darmowe</b>. Jednak, żeby z niej korzystać - trzeba się zalogować.
                    </Paragraph>
                    <Title level={3}>2. Na jakiej zasadzie to działa?</Title>
                    <Paragraph>
                        Cała magia aplikacji opiera się o indywidualnie wysyłane przez przeglądarkę powiadomienia.
                        Jeśli chcesz w pełni korzystać z możliwości SubstytucjeProcesowe - konieczne jest, aby zezwolić
                        przeglądarce wysyłać powiadomienia.
                    </Paragraph>
                    <Title level={3}>3. Mieszkam w Warszawie a substytucji szukam w Krakowie. Czy znajdę tu
                        zastępstwo?</Title>
                    <Paragraph>
                        Oczywiście! Wystarczy, że tworząc ogłoszenie wybierzesz interesujące Cię miasto, a odpowiedni
                        kandydaci zostaną o tym automatycznie poinformowani.
                    </Paragraph>

                    <Title level={3}>4. Czy wszyscy widzą moje dane?</Title>
                    <Paragraph>
                        Aby korzystać z portalu, trzeba być zalogowanym. Wtedy widoczne są tak zwane dane "publiczne".
                        Prywatne - m.in. dane kontaktowe, czy inne dane wrazliwe, przekazujemy tylko wybranemu przez
                        Ciebie pełnomocnikowi.
                    </Paragraph> <Title level={3}>5. Czy rejestracja jest czasochłonna?</Title>
                    <Paragraph>
                        Bynajmniej. Rejestracja przez Social media skraca proces do minimum. A samo korzystanie z
                        portalu
                        jest proste i intuicyjne.
                    </Paragraph>

                    <Title level={3}>6. Chciałbym dodać ofertę.</Title>
                    <Paragraph>
                        <Link to={appPath.offer.add} style={{fontSize: 18}}>Tutaj </Link>
                        znajdziesz formularz dodawania nowego ogłoszenia. Aby nie wprowadzać zamieszania - formularz
                        edycji wygląda tak samo ;)
                        Najważniejszymi polami są "Miasto" oraz "Rodzaj usługi". To one definiują gdzie trafi ogłoszenie, a tym
                        samym kto je zobaczy lub zostanie o nim poinformowany.
                        Dodatkowo pola oznaczone gwiazdką są wymagane.
                    </Paragraph>

                    <Title level={3}>7. Chciałbym znaleźć ofertę w swoim regionie.</Title>
                    <Paragraph>
                        <Link to={appPath.offer.list} style={{fontSize: 18}}>To </Link>
                        jest główny widok aplikacji. Na nim zobaczysz swoje obserwowane listy, swoje oferty czy
                        zgłoszenia.
                        Na początku widok może być całkiem pusty, nie przejmuj się tym, tylko kliknij w przycisk "{<Icon
                        type={"plus-circle"}/>}Dodaj Listę", aby dodać pierwszą listę.
                        Każda lista określa miasto oraz typ usługi który Cię interesuje.
                        Tym samym prezentujemy Ci tylko oferty, które faktycznie mogą Cię zainteresować.
                        Gdy tylko pojawi się oferta spełniająca Twoje kryteria - powiadomimy Cię wysyłając notyfikację
                        (w przeglądarce lub na telefon)!
                    </Paragraph>


                    <Title level={3}>8. Znalazłem ciekawą ofertę, jak mogę zaaplikować?</Title>
                    <Paragraph>
                        Na widoku ze szczegółami oferty kliknij "Wyślij zgłoszenie".
                        Zostaniesz poproszony o wypełnienie formularza zawierającego przede wszystkim Twoje dane
                        kontaktowe oraz wiadomość do autora ogłoszenia.
                        A w przypadku gdy autor nie określił wynagrodzenia za tę usługę - również swoje oczekiwania
                        finansowe.
                        Następnie - zostaniesz poinformowany jeśli Twoje zgłoszenie zostanie wybrane.
                    </Paragraph>

                    <Title level={3}>9. Przy mojej ofercie jest wiele zgłoszeń - co się stanie gdy jedno z nich
                        zaakceptuję?</Title>
                    <Paragraph>
                        Wszystkie zgłoszenia zawierają dane, które mają Ci ułatwić decyzję. Po zaakceptowaniu
                        zgłoszenia, to jedno dostanie status zaakceptowanego.
                        Twój nowy pełnomocnik zostanie o tym poinformowany notyfikacją, a na ofercie pojawią mu się
                        dotychczas niewidoczne dane prywatne (m.in. kontaktowe).
                        Pozostali aplikujący dostaną status "odrzucone".
                    </Paragraph>
                    <Title level={3}>10. Wybrany substytut przestał się odzywać, co robić?</Title>
                    <Paragraph>
                        Niestety, na pewne rzeczy nie mamy wpływu.
                        Natomiast, nawet po zamknięciu oferty, możesz zobaczyć wszystkie zgłoszenia do tej oferty,
                        a tym samym masz dane kontaktowe do listy zainteresowanych substytutów.
                    </Paragraph>
                </Row>
            </Content>
        </Layout>
    );
};

import React from "react";
import {Affix, Col, Icon, Layout} from "antd";
import {RouteComponentProps} from "react-router";
import {BenefitsSection} from "../components/BenefitsSection";
import {MainHomepageSection} from "../components/MainHomepageSection";
import {OffersPreviewList} from "../components/OffersPreviewList";
import {FAQSection} from "../components/FAQSection";

const {Content} = Layout;

interface IProps extends RouteComponentProps {
    isAuthenticated: boolean;
    isAnonymous: boolean;
}

export const HomepageView = (props: IProps) => {
    const redirectOnClick = (url: string) => {
        props.history.push(url)
    };
    return (
        <>
              <div className={"facebook-sign"}
                ><a href={`https://facebook.com/substytucje`} target={"_blank"}><Icon type={"facebook"} style={{
                    fontSize: 46, color: "#626d8e", cursor: "pointer"}}/></a>
                </div>
            <Layout>
                <Content id="hero-image" style={{paddingTop: "5%", backgroundImage: `url(${process.env.PUBLIC_URL}/law_04.png)`, backgroundSize: "45%", backgroundRepeat: "no-repeat", backgroundPosition: "right"}}>
                    <MainHomepageSection isAuthenticated={props.isAuthenticated} isAnonymous={props.isAnonymous}/>
                </Content>
            </Layout>

            <Layout style={{padding: "4% 4% 2% 4%", backgroundColor: "#f8fafd"}}>
                <Content>
                    <OffersPreviewList onRedirectClick={redirectOnClick}/>
                </Content>
            </Layout>

            <Layout style={{padding: "4% 4% 4% 4%", backgroundColor: "#f8fafd"}}>
                <Content>
                    <BenefitsSection/>
                </Content>
            </Layout>

            <Layout style={{padding: "4% 4% 4% 4%", backgroundColor: "#f8fafd"}}>
                <Content>
                    <FAQSection />
               </Content>
            </Layout>
        </>
    );
};

import React from "react";
import {Typography} from "antd";
import {map} from "lodash";

const {Title} = Typography;

interface IProps {
    level?: 1 | 2 | 3 | 4;
    title: string;
    descriptionPoints: string[];
}

export const TOSParagraph = (props: IProps) => {
    return (
        <div>
            <Title level={props.level || 3} style={{textAlign: "center"}}>§ {props.title}</Title>
            <ol>
                {map(props.descriptionPoints, (description: string) => {
                    return (
                        <li>
                            {description}
                        </li>
                    )
                })}
            </ol>
        </div>
    )
}

export const firebaseConfig = {
    apiKey: "AIzaSyCsHi7r6ynPxYPCPfni7xHavSMkd6SWY8o",
    authDomain: "substytucjeprocesowe.pl",
    databaseURL: "https://replacement-app.firebaseio.com",
    projectId: "replacement-app",
    storageBucket: "replacement-app.appspot.com",
    messagingSenderId: "842334083317",
    appId: "1:842334083317:web:62c23981fe282cee",
    measurementId: "G-298D0B2VH9"
};

export const webPushCertificateKey = "BE1iQE4qtjN5iogtowTOgj3Uvx_uH-RHbIn0pqlv0bSQdqGYIVW7mhLMnYyyx6DcrnZ_seo3lnA2Me1VdIbly8o";

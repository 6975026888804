let CURRENT_DOMAIN: string;
export let CURRENT_SITE: string;

if (process.env.REACT_APP_BUILD_ENV === "production") {
    CURRENT_DOMAIN = "substytucjeprocesowe.pl";
    CURRENT_SITE = `https://${CURRENT_DOMAIN}`;
}
else {
    CURRENT_DOMAIN = "replacement-dev.web.app";
    CURRENT_SITE = `https://${CURRENT_DOMAIN}`;
}

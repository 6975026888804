import firebase from "firebase/app";
import {firestoreDb} from "../../firebase/initialize_firebase_app";
import {collectionPath} from "../../firebase/collection_path";
import {IOffer, IOfferPrivate} from "../../firebase/documents/IOffer";
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;


export interface IOfferDetailOffer extends IOffer {
    id: string;
}

export const fetchOfferDetail = (offerId: string): Promise<IOfferDetailOffer | null> => {
    return firestoreDb
        .collection(collectionPath.offers)
        .doc(offerId)
        .get()
        .then((doc: DocumentSnapshot): IOfferDetailOffer => ({...doc.data() as IOffer, id: doc.id}))
        .catch(() => null); // we base 404 on it
};


export interface IOfferDetailOfferPrivate extends IOfferPrivate {}

export const fetchOfferPrivateDetail = (offerId: string): Promise<IOfferDetailOfferPrivate | null> => {
    return firestoreDb
        .collection(collectionPath.offersPrivate({offerId}))
        .doc(offerId)
        .get()
        .then((doc: DocumentSnapshot): IOfferDetailOfferPrivate => doc.data() as IOfferPrivate)
        .catch(() => null); // we base 404 on it
};

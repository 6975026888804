import {Row, Steps} from "antd";
import React, {useState} from "react";


interface IProps {
    type: string;
}
const {Step} = Steps;
export const FlowStepsDescription = (props: IProps) => {
    const [currentOne, setCurrentOne] = useState<number | undefined>(undefined);
    const [currentTwo, setCurrentTwo] = useState<number | undefined>(undefined);
    return props.type ? (
        <div style={{margin: "10% 3%"}}>
            {props.type === "#zlecen" && (
                <Row>
                    <Steps current={currentOne} onChange={(i: number) => setCurrentOne(i)}>
                        <Step title="Krok 1"
                              description={"Dodaj preferencję miasta i usługi poprzez dodanie listy, które Cię interesują, aby zobaczyć aktualne oferty"}/>
                        <Step title="Krok 2"
                              description={"Wyślemy Ci powiadomienie, gdy na Twojej liście pojawi się nowa oferta"}/>
                        <Step title="Krok 3" description={"Wyslij zgłoszenie na ofertę"}/>
                        <Step title="Krok 4"
                              description={"Poinformujemy Cię jeśli zostaniesz zaakceptowany oraz wyślemy wszystkie potrzebne informacje"}/>
                    </Steps>
                </Row>
            )}

            {props.type === "#substytuta" && (
                <Row>
                    <Steps current={currentTwo} onChange={(i: number) => setCurrentTwo(i)}>
                        <Step title="Krok 1"
                              description={"Dodaj ofertę, uzupełniając ogłoszenie o wymagane dane"}/>
                        <Step title="Krok 2"
                              description={"Wyślemy Ci powiadomienie, gdy do Twojej oferty pojawią się pierwsze zgłoszenia"}/>
                        <Step title="Krok 3"
                              description={"W klarowny sposób przedstawimy Ci zgłoszenia i kandydatów"}/>
                        <Step title="Krok 4"
                              description={'Wybierz zgłoszenie, a my przekażemy "prywatne dane" wybranemu kandydatowi'}/>
                    </Steps>
                </Row>
            )}
        </div>
    ) : null
};

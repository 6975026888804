import {collectionPath} from "../../firebase/collection_path";
import {firestoreDb} from "../../firebase/initialize_firebase_app";
import {IOffer, IOfferClose, IOfferUpdate} from "../../firebase/documents/IOffer";
import {waitForData} from "../../firebase/actions/wait_for_data";
import {IOfferDetailOffer} from "./fetch_offer_detail";
import {OfferStatus} from "../../firebase/fields/OfferStatus";


export const updateOffer = (offerId: string, offer: IOfferUpdate): Promise<IOfferDetailOffer | void> => {
    const docRef = firestoreDb.collection(collectionPath.offers).doc(offerId);
    return docRef
        .update(offer)
        .then(async () => {
            const data = await waitForData("updateOffer")<IOffer>(docRef, data => data.status === OfferStatus.PUBLISHED);
            return {...data, id: docRef.id} as IOfferDetailOffer;
        })
        .catch(e => {
            console.error("updateOffer", offerId, offer, e);
            throw e;
        });
};

export const closeOffer = (offerId: string, offer: IOfferClose): Promise<IOfferDetailOffer | void> => {
    const docRef = firestoreDb.collection(collectionPath.offers).doc(offerId);
    return docRef
        .update(offer)
        .then(async () => {
            const data = await waitForData("closeOffer")<IOffer>(docRef, data => data.status === OfferStatus.CLOSED);
            return {...data, id: docRef.id} as IOfferDetailOffer;
        })
        .catch(e => {
            console.error("closeOffer", offerId, offer, e);
            throw e;
        });
};

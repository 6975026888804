import React, {useEffect} from 'react';
import * as firebase from 'firebase/app';
import '../../../node_modules/firebaseui/dist/firebaseui.css';
import {firebaseUiAuth} from "../../firebase/initialize_firebase_app";
import {Link} from "react-router-dom";
import {appPath} from "../../app/utils/app_path";


const LOGIN_CONTAINER_ID = 'firebaseui-auth-container';

interface IProps {
    successUrl?: string;
}
export const AuthButtons = (props: IProps) => {
    useEffect(() => {
        initializeFirebaseUiAuthButtons(`#${LOGIN_CONTAINER_ID}`, props.successUrl);
    }, []);

    return <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <div id={LOGIN_CONTAINER_ID}/>
        <div style={{fontSize: 14}}>Akceptuję <Link to={appPath.tos}> Regulamin </Link> serwisu oraz zapoznałem się z <Link to={appPath.privacy}> Polityką Prywatności </Link> </div>
    </div>;
};

/**
 * Helper
 */
const initializeFirebaseUiAuthButtons = (selector: string, successUrl?: string) => {
    const uiConfig = {
        signInSuccessUrl: successUrl || '/',
        signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            {
                provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                scopes: [
                    'public_profile'
                ]
            }
            // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            // firebase.auth.GithubAuthProvider.PROVIDER_ID,
            // firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
        ],
    };

    // The start method will wait until the DOM is loaded.
    firebaseUiAuth.start(selector, uiConfig);
    // WorkAround to hide this

};


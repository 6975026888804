import React from "react";
import {Col, Layout, Row, Typography} from "antd";
import {TOSParagraph} from "../components/TOSParagraph";
import {PrivacyPolicy} from "../components/PrivacyPolicy";

const {Content} = Layout;
const {Title} = Typography;

interface IProps {
}

export const TOSView = (props: IProps) => {
    return (
            <Layout style={{padding: "4%"}}>
                <Content>
                    <Row>
                        <Title style={{textAlign: "center"}}> Regulamin serwisu internetowego SubstytucjeProcesowe.pl</Title>
                        <br/>
                        <Col>
                            <TOSParagraph
                                level={3}
                                title={"1. Wstęp"}
                                descriptionPoints={[
                                    `Niniejszy Regulamin określa: ogólne warunki, rodzaje i zakres świadczonych usług oraz zasady korzystania i funkcjonowania Serwisu.`,
                                    `Serwis dostępny jest za pośrednictwem sieci Internet i strony internetowej https://SubstytucjeProcesowe.pl.`,
                                    `Każdy Użytkownik zobowiązany jest do przestrzegania postanowień niniejszego Regulaminu.`,
                                    `Akceptacja treści regulaminu jest warunkiem koniecznym do założenia konta w Serwisie.`,
                                    `Usługodawca udostępnia Użytkownikowi Regulamin przed rejestracją w Serwisie. Usługodawca umożliwia Użytkownikowi bezpłatne pozyskanie, odtworzenie i utrwalenie Regulaminu w każdym czasie.`,
                                    `Dokonując rejestracji w Serwisie Użytkownik potwierdza, że umowę o świadczenie przez Usługodawcę Usługi zawarł w zakresie swojej działalnością gospodarczej lub zawodowej, tj. nie jako konsument w rozumieniu art. 22[1] kodeksu cywilnego.`,
                                    `Właścicielami Serwisu są Tomasz Ciecierski i Michał Skoczylas. Prowadzenie Serwisu nie ma charakteru zarobkowego i nie wiąże się z prowadzeniem działalności gospodarczej.`
                                ]}
                            />
                            <Title level={3}  style={{textAlign: "center"}}>§ 2. Definicje</Title>
                            <ul>
                                <li>
                                    <b>SubstytucjeProcesowe lub Serwis </b> - platforma internetowa udostępniona na rzecz użytkowników serwisu znajdująca się pod adresem https://SubstytucjeProcesowe.pl.
                                </li>
                                <li>
                                    <b>Usługodawca</b> - wydawcy Serwisu - Tomasz Ciecierski, Michał Skoczylas.
                                </li>
                                <li>
                                    <b>Użytkownik</b> - każda osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, która korzysta z Usług świadczonych przez Serwis.
                                </li>
                                <li>
                                    <b>Dane Użytkownika </b> - Dane, które pozyskaliśmy podczas Rejestracji Użytkownika (pochodzące z portalu Facebook) - nazwa wyświetlana, email, zdjęcie oraz link do profilu publicznego.
                                </li>
                                <li>
                                    <b>Dane Kontaktowe Użytkownika </b> - Adres email, telefon kontaktowy, podane w Ofercie.
                                </li>
                                <li>
                                    <b>Usługa </b> - świadczona drogą elektroniczną usługa polegająca na możliwości dodawania Ofert lub wysyłania Zgłoszeń
                                </li>
                                <li>
                                    <b>Autor</b> - Użytkownik Serwisu, który dodał Ofertę.
                                </li>
                                <li>
                                    <b>Substytut</b> - Użytkownik Serwisu, który wysłał Zgłoszenie do Oferty, a następnie został zaakceptowany przez autora Oferty.
                                </li>
                                <li>
                                    <b>Oferta</b> - wszelkiego rodzaju ogłoszenia dodane przez Użytkownika.
                                </li>
                                <li>
                                    <b>Konto </b> - zbiór zasobów i ustawień utworzonych w Serwisie dla Użytkownika.
                                </li>
                                <li>
                                    <b>Nawiązanie Współpracy</b> - relacja następująca tylko pomiędzy autorem Oferty, a Substytutem.
                                </li>
                                <li>
                                    <b>Usługa elektroniczna</b> - usługa świadczona drogą elektroniczną przez Usługodawcę na rzecz Użytkownika za pośrednictwem Serwisu zgodnie z Regulaminem.
                                </li>
                                <li>
                                    <b>Adres Kontaktowy</b> - adres poczty elektronicznej kontakt@substytucjeprocesowe.pl
                                </li>
                                <li>
                                    <b>Regulamin</b> - niniejszy dokument dostępny na stronie SubstytucjeProcesowe.pl.
                                </li>
                            </ul>
                            <TOSParagraph
                                level={3}
                                title={"3. Postanowienia ogólne"}
                                descriptionPoints={[
                                    `Głównym celem działania Serwisu jest umożliwienie Użytkownikom nawiązywania i podtrzymywania kontaktów z innymi Użytkownikami Serwisu, w celu wzajemnej wymiany informacji zamieszczonych w Serwisie, dodawania Ofert i aplikowania na Oferty zamieszczone przez innych Użytkowników.`,
                                    `Do korzystania z usług Serwisu niezbędne jest posiadanie urządzenia pozwalającego na korzystanie z zasobów Internetu oraz przeglądarki zasobów internetowych umożliwiającej wyświetlanie stron www.`,
                                    `Usługodawca nie jest stroną umów, porozumień lub innych czynności prawnych dokonywanych pomiędzy Użytkownikami, w związku z czym Usługodawca nie jest odpowiedzialny za skutki niewykonania lub nienależytego wykonania zobowiązań powiązanych z Ofertami znalezionymi w Serwisie SubstytucjeProcesowe, a także za zdolność Użytkowników do składania, przyjmowania i realizacji usług.`,
                                    `Usługodawca nie gwarantuje znalezienia Substytuta do każdej Oferty.`,
                                    `Wszelkie informacje wyświetlone w Ofertach oraz Zgłoszeniach są treściami Użytkowników. Usługodawca nie odpowiada za treść i formę wprowadzanych do Serwisu informacji.`,
                                    `Użytkownik zamieszcza treści w Serwisie na własną odpowiedzialność. Usługodawcy przysługuje prawo do usunięcia bądź edycji treści, które uzna za bezprawne lub niezgodne z Regulaminem, a także prawo do zablokowania dostępu do Serwisu w stosunku do Użytkowników notorycznie naruszających postanowienia Regulaminu.`,
                                    `Usługodawca wskazuje, że zgodnie z art. 14 ust. 1 ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz.U. 2002 nr 144, poz. 1204 ze zm.) nie ponosi odpowiedzialności za przechowywane dane ten, kto udostępniając zasoby systemu teleinformatycznego w celu przechowywania danych przez usługobiorcę nie wie o bezprawnym charakterze danych lub związanej z nimi działalności, a w razie otrzymania urzędowego zawiadomienia lub uzyskania wiarygodnej wiadomości o bezprawnym charakterze danych lub związanej z nimi działalności niezwłocznie uniemożliwi dostęp do tych danych.`
                                ]}
                            />
                            <TOSParagraph
                                level={3}
                                title={"4. Zasady korzystania z Serwisu"}
                                descriptionPoints={[
                                    `Zawarcie umowy o świadczenie Usługi następuje: poprzez założenia Konta w Serwisie logowania co jednoznaczne jest z zaakceptowaniem Regulaminu.`,
                                    "Serwis umożliwia dodawanie ogłoszeń zwanych w niniejszym regulaminie Ofertami, oraz aplikowanie na istniejące ogłoszenia. Co w efekcie pozwala skontaktować ze sobą dwie strony.",
                                    "W celu zawarcia umowy dotyczącej Oferty, zaleca się kontakt stron drogą mailową lub telefoniczną - poza Serwisem.",
                                    `W celu wykazania chęci podjęcia Oferty - Użytkownik powinien zgłosić się poprzez przyciśnięcie przycisku "Wyślij zgłoszenie" widocznego pod Ofertą.`,
                                    `Użytkownik wyraża zgodę na otrzymywanie powiadomień z Serwisu.`,
                                    `Reklamacje dot. problemów z korzystaniem z Serwisu mogą być zgłaszane Usługodawcy pocztą elektroniczną na Adres Kontaktowy.`
                                ]}
                            />

                            <Title level={3}  style={{textAlign: "center"}}>§ 5. Opis Funkcjonalności Serwisu</Title>
                            {/*// Dashboard */}
                            <Title level={4}>Dashboard</Title>
                            <ol>
                                <li>Głównym widokiem w Serwisie, dostępnym tylko dla zalogowanego Użytkownika jest widok
                                    Dashboardu.
                                </li>
                                <li>Podzielony jest on na trzy sekcje: "Moje Zgłoszenia", "Moje Listy", "Moje Oferty".
                                </li>
                                <ul>
                                    <li><b>Moje Zgłoszenia -</b> zawierają wszystkie wysłane przez Użytkownika
                                        Zgłoszenia, razem ze statusem tego zgłoszenia, po kliknięciu w które Użytkownik
                                        przenoszony
                                        jest na widok Oferty.
                                    </li>
                                    <div><b>Status zgłoszenia -</b> "Oczekujące", "Zaakceptowane", "Odrzucone" -
                                        określają czy Autor już podjął decyzję i wybrał Substytuta. Status
                                        jest odzwierciedlony kolorem.
                                    </div>
                                    <div><b>Filtry -</b> pozwalają ustawić ograniczenia w wyświetlaniu Zgłoszeń o
                                        konkretnych statusach, np. jeśli Użytkownik chce zobaczyć tylko Zgłoszenia do
                                        Ofert, w których
                                        jego Zgłoszenie zostało zaakceptowane.
                                    </div>
                                    <li><b>Mój Pulpit -</b> zawiera wszystkie dodane Listy, a pod nimi wszystkie
                                        Oferty spełniające preferencje użytkownika.
                                    </li>
                                    <div><b>Listy -</b> Zestaw składający się z dwóch danych - "Miasto" oraz
                                        "Rodzaj Usługi", które pomagają filtrować Oferty według preferencji Użytkownika,
                                        na przykład Warszawa, Fotokopie.
                                    </div>
                                    <div><b>Dodaj Listę -</b> Przycisk otwierający okno dialogowe (modal), w
                                        którym Użytkownik może wybrać preferencje z listy.
                                    </div>
                                    <div><b>Usuń -</b> Przycisk pozwalający usunąć listę, co spowoduje, że Oferty z
                                        tymi preferencjami nie będą się wyświetlać, ani wysyłać notyfikacji.
                                    </div>
                                    <li>
                                        <b>Moje Oferty -</b> lista dodanych przez Użytkownika Ofert.
                                        <div>Zawiera informacje o ilości Zgłoszeń, statusie Oferty, oraz umożliwia
                                            wejscie w tryb Edycji Oferty.</div>
                                    </li>
                                </ul>
                            </ol>

                            {/*// Dodawanie Oferty */}
                            <Title level={4}>Dodawanie Oferty</Title>
                            <ol>
                                <li>Każdy zalogowany Użytkownik może dodać Ofertę, która musi określać "miasto" oraz "rodzaj usługi".
                                    Na podstawie tych dwóch danych wysyłane są powiadomienia do innych Użytkowników, których preferencje odpowiadają podanemu miastu i usłudze.</li>
                                  <li>
                                    Warunkiem utworzenia Oferty jest uzupełniene pól: "Miasto", "Rodzaj Usługi", "Data", oraz jedno z pól adresowych - są wymagane do utworzenia Oferty.
                                </li>
                                <li>
                                    Dodatkowo do Oferty można dodać następujące informacje: "Godzina", "Opis",
                                    "Nazwa placówki", "Adres placówki", "Adres (Poczta)", "Wymagania", "Wynagrodzenie", "Faktura VAT", "Email kontaktowy", "Telefon kontaktowy", oraz "Opis Ukryty".
                                </li>
                                <li>
                                    "Email kontaktowy", "Telefon kontaktowy", oraz "Opis Ukryty" - nie są dostępne publicznie, nie widzą ich nawet zalogowani Użytkownicy. Dostęp do tych danych ma tylko Substytut wybrany przez Autora.
                                </li>
                                <li>Użytkownik ma w każdej chwili możliwość edycji Oferty.</li>
                                <li>Użytkownik może zgłosić prośbę o usunięcie Oferty poprzez kontakt mailowy na Adres Kontaktowy.</li>
                                <li>Oferta jest usuwana z listy Ofert po przekorczeniu określonego w Ofercie terminu.</li>
                                <li>Na widoku szczegółów Oferty, autor Oferty zobaczy wszystkie Zgłoszenia, z których może wybrać tylko jedno.</li>
                                <li>Użytkownik ma możliwość zamknięcia oferty w dowolnej chwili poprzez kliknięcie przycisku "Zamknij Ofertę" na widoku Edycji.</li>

                            </ol>
                            {/*// Zgłaszanie się do Oferty */}
                            <Title level={4} > Zgłaszanie się do Oferty</Title>
                            <ol>
                                <li>Każdy zalogowany Użytkownik może zobaczyć każdą Ofertę i tym samym wysłać na nią Zgłoszenie.</li>
                                <li>Zgłoszenie musi zawierać jedno z dostępnych danych kontaktowych - "Telefon" lub "Email".
                                    Jeśli autor Oferty nie podał oferowanego wynagrodzenia, to Zgłoszenie będzie wymagało też podania oczekiwanego wynagrodzenia.
                                    Opcjonalne jest też podanie wiadomości do autora Oferty.</li>
                                <li>W momencie zaakceptowania Zgłoszenia przez Autora, zmieni się jego status na Zaakceptowane, a status Oferty na "Zamknięte".
                                    Wybrany Substytut dostanie notyfikację z przeglądarki oraz w Ofercie pojawią się ukryte dotychczas dane prywatne Oferty.
                                    Zgłoszenia innych Użytkowników, którzy aplikowali na tę Ofertę, dostaną status "Odrzucone".
                                </li>
                                <li>W chwili zaakceptowania Zgłoszenia obie strony tj. autor Oferty jak i Substytut, otrzymają za pośrednictwem notyfikacji powiadomienie o tej okoliczności,
                                    a dane prywatne w Ofercie i Zgłoszeniu staną się widoczne dla drugiej strony.
                                    Od tej aplikowanie na tę Ofertę staje się niemożliwe dla innych Użytkowników Serwisu.
                                </li>
                            </ol>

                            {/*// Notyfikacje */}
                            <Title level={4} > Notyfikacje</Title>
                            <ol>
                                <li><b>Notyfikacje wysyłane są tylko Użytkownikom, którzy pozwolą przeglądarce na ich wysyłanie.</b></li>
                                <li>Notyfikacje wysyłane są w kilku sytuacjach:</li>
                                <ul>
                                    <li>gdy pojawi się nowa Oferta odpowiadająca preferencjom Użytkownika,</li>
                                    <li>gdy pojawią się nowe Zgłoszenia do Oferty której Użytkownik jest autorem,</li>
                                    <li>gdy Użytkownik został wybrany do wykonania Oferty na którą wcześniej wysłał Zgłoszenie,</li>
                                </ul>
                                <li>Aby nieotrzymywać notyfikacji, Użytkownik powinien w ustawieniach przeglądarki wyłączyć zgodę dla Serwisu.</li>
                            </ol>

                            {/*// Rejestracja */}
                            <Title level={4}> Rejestracja</Title>
                            <ol>
                                {/*// TODO do przemyslenia*/}
                                {/*<li>Umowa o świadczenie Usług drogą elektroniczną zostaje zawarta przez Użytkownika z chwilą skorzystania z dowolnych Usług oferowanych przez Serwis Usługodawcy.</li>*/}
                                <li>Użytkownik ma umożliwiony tylko jeden sposób rejestracji - przez portal społęcznościowy Facebook.</li>
                                <li>SubstytucjeProcesowe, po pomyślnym udzieleniu zgody na użycie konta facebook do logowania w Serwisie, utworzy dla Użytkownika w ramach Serwisu unikalne Konto.</li>
                                <li>Serwis pozyskuje z portalu Facebook następujące dane o Użytkowniku: "Nazwa wyświetlana", "Adres email", "Zdjęcie główne", "Link do profilu publicznego".</li>
                            </ol>


                            {/*// RODO*/}
                            <Title level={3} style={{textAlign: "center"}}>§ 6. Przetwarzanie danych osobowych</Title>
                            <PrivacyPolicy />

                            <TOSParagraph
                                level={3}
                                title={"7. Postanowienia końcowe"}
                                descriptionPoints={[
                                    `Prawa i obowiązki SubstytucjeProcesowe określa wyłącznie niniejszy Regulamin oraz bezwzględnie obowiązujące przepisy prawa.`,
                                    `Usługodawca zastrzega sobie prawo zmiany zasad i warunków korzystania z Usług Serwisu lub wprowadzenia nowego regulaminu.`,
                                    `Usługodawca świadczy usługi drogą elektroniczną zgodnie z regulaminem.`
                                ]}
                            />
                            {/*<TOSParagraph*/}
                                {/*level={3}*/}
                                {/*title={"6. Reklamy"}*/}
                                {/*descriptionPoints={[*/}
                                    {/*"Usługodawca informuje, że w Serwisie prezentowane mogą być treści informacyjne oraz treści reklamowe dotyczące usług oraz towarów Usługodawcy lub osób trzecich, na co Użytkownik niniejszym wyraża zgodę."*/}
                                {/*]}*/}
                            {/*/>*/}
                        </Col>
                    </Row>
                </Content>

            </Layout>
    );
};

import React from "react";
import {Badge, Card, List, Typography} from "antd";
import moment from "moment";
import {IOfferListOffer} from "../actions/fetch_offer_list";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {OfferStatus} from "../../firebase/fields/OfferStatus";
import {pluralize} from "../../app/utils/pluralize";
import {getOfferTypeDisplayName} from "../../firebase/fields/OfferType";

const {Text, Paragraph} = Typography;


interface IProps extends RouteComponentProps {
    offer: IOfferListOffer;
}

export const OfferOwnShort = withRouter((props: IProps) => {
    const {offer} = props;
    const date = moment(offer.date.toDate());
    const daysRemaining = date.diff(moment(), "days");
    const pluralDays = pluralize(["dzień", "dni", "dni"]);
    const offerLink = `${offer.id}/`;
    const editLink = `${offerLink}edycja/`;
    const isOfferActive = offer.status === OfferStatus.PUBLISHED;

    const renderStatus = () => {
        if (isOfferActive) {
            if (offer.applicationCount > 0) {
                return <><Badge status={"success"} text={"Sprawdź zgłoszenia i podejmij decyzję"} /></>;
            } else {
                return <><Badge status={"processing"} text={"Oferta oczekuje na zgłoszenia"} /></>;
            }
        } else if (offer.status === OfferStatus.CLOSED) {
            return (
                <>
                    <Badge status={"error"} text={"Oferta zamknięta"} />
                    <Text>
                        {" - Termin: "}
                        {daysRemaining === 0 ? "dzisiaj" : (
                            daysRemaining > 0 ?
                                `za ${daysRemaining} ${pluralDays(daysRemaining)}` :
                                `${-1 * daysRemaining} ${pluralDays(-1 * daysRemaining)} temu`
                        )}
                    </Text>
                </>
            );
        }
    };
    return (
        <List.Item>
            <div onClick={() => props.history.push(offerLink)}>
                <Card
                    hoverable
                    title={`${getOfferTypeDisplayName(offer.type)} ${offer.city}`}
                    style={{height: 261}}
                    extra={isOfferActive && <Link to={editLink} onClick={(e: any) => e.stopPropagation()}>Edytuj </Link>}
                    actions={[renderStatus()]}
                >
                    <Paragraph ellipsis={{rows: 5}}>
                        <Text style={{fontSize: 20}}>{date.format("DD.MM.YYYY")}</Text>
                        <br/>
                        <Text style={{fontSize: 20}}>{offer.addressName}</Text>
                        <br/>

                        <Text>Ilość zgłoszeń: {offer.applicationCount}</Text>
                        <br/>

                    </Paragraph>
                </Card>
            </div>
        </List.Item>
    )
});

import React, {useEffect, useState} from "react";
import {fetchOffersPreviewList, IOfferPreviewWithId} from "../../offer/actions/fetch_offer_list";
import {OfferPreviewListItem} from "./OfferPreviewListItem";
import {List, Typography} from "antd";
import {OfferType} from "../../firebase/fields/OfferType";


const defaultSkeletonObject = {
    addressName: "",
    city: "",
    type: OfferType.OTHER,
    id: ""
};
const defaultSkeletonData = [defaultSkeletonObject, defaultSkeletonObject, defaultSkeletonObject, defaultSkeletonObject];
const {Title} = Typography;
interface IProps {
    onRedirectClick: (url: string) => void;
}
export const OffersPreviewList = (props: IProps) => {
    // TODO: why do we need such skeleton data? Maybe placeholders should be based on `null` (empty value) instead of mocked-object (empty structure)
    const [offersPreviews, setOffers] = useState<IOfferPreviewWithId[]>(defaultSkeletonData as any);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        (async () => {
            const offers = await fetchOffersPreviewList();
            setOffers(offers);
            setLoading(false)
        })();
    }, []);

    return (
        <>
            <Title level={2}>Ostatnio dodane oferty</Title>
            <List
                grid={{
                    gutter: 10,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 4,
                    xxl: 4,
                }}
                size="large"
                dataSource={offersPreviews}
                locale={{emptyText: "Nie udało się pobrac ofert"}}
                renderItem={(preview) => (
                    <OfferPreviewListItem isLoading={isLoading} offer={preview} onRedirectClick={props.onRedirectClick}/>
                )}
            />
            </>
    )
};
